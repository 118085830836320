import classNames from 'classnames'

import styles from './switch.module.scss'

export const Switch = ({
    className = '',
    labelLeft = '',
    labelRight = '',
    onClick = () => {},
    isLeft = true,
    switchClassName = '',
    ballClassName = '',
    textClassName = '',
    isSmall,
}) => {
    return (
        <div
            className={classNames(
                styles.switch,
                {
                    [styles.left]: isLeft,
                    [styles.small]: isSmall,
                },
                className
            )}
            onClick={onClick}
        >
            <p className={textClassName}>{labelLeft}</p>
            <div
                className={classNames(
                    styles.switchElement,
                    {
                        ['!w-[20px] !h-[10px]']: isSmall,
                    },
                    switchClassName
                )}
            >
                <div
                    className={classNames(
                        styles.ball,
                        {
                            ['!w-[6px] !h-[6px] !top-[1px] !left-[1px]']:
                                isSmall,
                        },
                        ballClassName
                    )}
                />
            </div>
            <p className={textClassName}>{labelRight}</p>
        </div>
    )
}
