import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useNavigationContext } from 'contexts/navigationContext'

export function Link({
    href = '',
    className = '',
    children,
    external = false,
    blank = false,
    label = `Link to ${href}`,
    style = {},
    rel = '',
    onClick = () => {},
    ...props
}) {
    const router = useRouter()
    const { setOverlayScreen } = useNavigationContext()

    if (blank || external)
        return (
            <a
                href={href}
                target={blank ? '_blank' : '_self'}
                rel={`noopener noreferrer ${rel}`}
                className={className}
                aria-label={label}
                style={style}
                onClick={e => {
                    onClick()
                    e.stopPropagation()
                }}
                {...props}
            >
                {children}
            </a>
        )

    return (
        <NextLink
            href={href}
            data-active={router.asPath.startsWith(href)}
            className={className}
            aria-label={label}
            onClick={e => {
                e.stopPropagation()
                onClick()
                if (router.asPath === href) {
                    e.preventDefault()
                    setOverlayScreen(null)
                }
            }}
            style={{
                pointerEvents: 'all',
                ...style,
            }}
            {...props}
        >
            {children}
        </NextLink>
    )
}

export const ComponentWithLink = ({
    link = null,
    blank = false,
    external = false,
    children,
}) => {
    if (!link) return children

    return (
        <Link href={link} blank={blank} external={external}>
            {children}
        </Link>
    )
}
