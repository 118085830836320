export const providerSections = [
    {
        id: 'about',
        slug: '',
        title: 'About',
    },
    {
        id: 'supportedAssets',
        slug: 'supported-assets',
        title: 'Supported Assets',
    },
    {
        id: 'enterprise',
        slug: 'enterprise',
        title: 'Enterprise',
    },
    {
        id: 'analytics',
        slug: 'analytics',
        title: 'Analytics',
    },
]

export const providerSectionsIds = providerSections.reduce(
    (acc, { id }) => ({ ...acc, [id]: id }),
    {}
)
