import classNames from 'classnames'
import { Modal } from 'components/modal'
import { useClickOutside, useScreenSize } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { scrollIntoView } from 'utils/actions'
import { NO_DATA_INDICATOR } from 'utils/formatter'

import styles from './tableOfContents.module.scss'

const ItemsList = ({
    choices = [],
    onSelect = () => {},
    selectedKey = '',
    isDropdown = false,
}) => {
    return (
        <ul
            className={classNames('flex flex-col w-full', {
                ['overflow-y-auto overflow-x-hidden max-h-[220px]']: isDropdown,
            })}
        >
            {choices.map((choice, idx) => (
                <li
                    key={`choice-${choice?.key}-${idx}`}
                    className={`cursor-pointer font-bold ${
                        isDropdown ? 'text-xs' : 'text-sm'
                    } py-2 px-3 hover:bg-[var(--c-grey-4)] ${
                        choice?.key === selectedKey
                            ? 'text-contrast-4'
                            : 'text-contrast-3'
                    }
                                            `}
                    onClick={() => {
                        onSelect(choice)
                    }}
                >
                    {choice?.name ?? NO_DATA_INDICATOR}
                </li>
            ))}
        </ul>
    )
}

export function Dropdown({
    choices = [],
    selected = null,
    onSelect = () => {},
    title = 'Navigate',
}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    const { desktop } = useScreenSize()

    useClickOutside(dropdownRef, () => {
        if (desktop) {
            setIsOpen(false)
        }
    })

    return (
        <div ref={dropdownRef}>
            <div
                key={`choice-${selected?.key}-selected`}
                className={`flex flex-row gap-x-3 h-9 justify-between items-center w-full whitespace-nowrap cursor-pointer bg-[var(--c-grey-8)] py-3 px-6 leading-none ${
                    isOpen ? 'rounded-tl-lg rounded-tr-lg' : 'rounded-lg'
                } hover:shadow-lg`}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <p className='text-contrast-4 font-bold text-xs'>{title}</p>
                <span
                    className={`icon ${
                        !isOpen
                            ? 'icon-chevron-arrow-down top-2'
                            : 'icon-chevron-arrow-up'
                    } !bg-contrast-4 min-w-[10px] max-w-[10px]`}
                />
            </div>

            {isOpen &&
                (desktop ? (
                    <div className='relative'>
                        <div
                            className={classNames(
                                styles.options,
                                'absolute top-0 left-0 w-[220px] z-10'
                            )}
                        >
                            <div className='bg-contrast-0 shadow-lg rounded-b-lg rounded-tr-lg'>
                                <div className='flex flex-col w-full bg-[var(--c-grey-8)] px-4 py-6 rounded-b-lg rounded-tr-lg'>
                                    <p className='text-contrast-4 font-bold text-lg px-2 mb-2'>
                                        {title}
                                    </p>
                                    <ItemsList
                                        choices={choices}
                                        onSelect={choice => {
                                            onSelect(choice)
                                            setIsOpen(false)
                                        }}
                                        selectedKey={selected?.key}
                                        isDropdown
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Modal
                        className='px-8 py-6'
                        isOpen={isOpen}
                        onClose={() => {
                            setIsOpen(false)
                        }}
                    >
                        <p className='text-contrast-4 font-bold text-lg px-2 mb-6'>
                            {title}
                        </p>
                        <ItemsList
                            choices={choices}
                            onSelect={choice => {
                                onSelect(choice)
                                setIsOpen(false)
                            }}
                            selectedKey={selected?.key}
                            isDropdown={false}
                        />
                    </Modal>
                ))}
        </div>
    )
}

const useH2Titles = (htmlContent = '') => {
    const [h2Titles, setH2Titles] = useState([])

    useEffect(() => {
        const contentToParse = htmlContent
        const parser = new DOMParser()
        const root = parser.parseFromString(contentToParse, 'text/html')
        setH2Titles(
            Array.from(root.querySelectorAll('h2')).map(h2 => ({
                key: h2.id,
                name: h2.textContent,
            }))
        )
    }, [htmlContent])

    return h2Titles
}

// NB: To use it with the page content, add ids to h2 nodes
export const TableOfContents = ({
    titles = [],
    defaultOptionName = 'Navigate',
}) => {
    if (!titles?.length) {
        return null
    }

    return (
        <Dropdown
            className='w-[228px]'
            choices={titles}
            selected={{ key: '', name: defaultOptionName }}
            onSelect={({ key }) => {
                const element = document.getElementById(key)

                scrollIntoView(element)
            }}
            small
            title={defaultOptionName}
        />
    )
}

// NB: To use it with the page content, add ids to h2 nodes
export const TableOfContentsWithH2 = ({
    htmlContent = '',
    defaultOptionName = 'Navigate',
}) => {
    const titles = useH2Titles(htmlContent ?? document.body.innerHTML)

    return (
        <TableOfContents
            titles={titles}
            defaultOptionName={defaultOptionName}
        />
    )
}
