import { useSharedImageAdState } from 'components/ads/hooks'
import React, { createContext, useContext } from 'react'

const AnnouncementBarContext = createContext()

export const AnnouncementBarProvider = ({ children }) => {
    const { imageAdState } = useSharedImageAdState()


    return (
        <AnnouncementBarContext.Provider
            value={{ announcementBarState: imageAdState }}
        >
            {children}
        </AnnouncementBarContext.Provider>
    )
}

export const useAnnouncementBar = () => useContext(AnnouncementBarContext)
