import { useCallback, useState } from 'react'
import { useEventListener, useIsomorphicLayoutEffect } from './useEventListener'

export const useElementSize = () => {
    const [ref, setRef] = useState(null)
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    })

    const handleSize = useCallback(() => {
        setSize({
            width: ref?.scrollWidth || 0,
            height: ref?.scrollHeight || 0,
        })
    }, [ref?.scrollHeight, ref?.scrollWidth])

    useEventListener('resize', handleSize)

    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [ref?.scrollHeight, ref?.scrollWidth])

    return [setRef, size]
}
