import { useRouter } from 'next/router'

export function useRouterPages() {
    const router = useRouter()
    const path = router.asPath

    const isStakeAppPage = path.startsWith('/stake-app')
    const isSwapAppPage = path.startsWith('/swap')

    const isPortfolioDashboard = path.startsWith('/terminal/dashboard')
    const isPortfolioLandingPage =
        path.startsWith('/terminal') && !isPortfolioDashboard

    return {
        isStakeAppPage,
        isSwapAppPage,
        isPortfolioDashboard,
        isPortfolioLandingPage,
    }
}
