import Image from 'next/image'
import { Link } from './link'

import styles from './youtubeLink.module.scss'

export const YoutubeLink = ({ href = '#', label = 'Link' }) => {
    return (
        <Link className={styles.youtubeLink} href={href} blank>
            <span>{label}</span>
            <Image
                src={`/static/img/youtube-play-button.png`}
                width={20}
                height={20}
                alt={label}
                placeholder='empty'
                priority
            />
        </Link>
    )
}
