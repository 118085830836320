import { useEffect } from 'react'
import { signal } from '@preact/signals-react'

const STAGE = process.env.NEXT_PUBLIC_VERCEL_ENV

const key =
    STAGE === 'production' ? 'stakingrewards.com' : 'staging.stakingrewards.com'

const persist = state => window.localStorage.setItem(key, JSON.stringify(state))

export const THEME = {
    LIGHT: 'default',
    DARK: 'dark',
}

const initialized = signal(null)

const theme = signal(THEME.LIGHT)
const lastSeenAnnouncementId = signal(null)
const isDesktopNavbarOpen = signal(null)

export const storageState = {
    theme,
    lastSeenAnnouncementId,
    isDesktopNavbarOpen,
}

export function useWatchStorage() {
    const initializedValue = initialized.value
    const themeValue = theme.value
    const lastSeenAnnouncementIdValue = lastSeenAnnouncementId.value
    const isDesktopNavbarOpenValue = isDesktopNavbarOpen.value

    useEffect(() => {
        if (!initializedValue) {
            initialized.value = true

            const localStorageItem = window.localStorage.getItem(key)
            const localDB = localStorageItem
                ? JSON.parse(localStorageItem)
                : null

            if (localDB && Object.keys(localDB).length) {
                theme.value = localDB.theme ?? themeValue

                lastSeenAnnouncementId.value =
                    localDB.lastSeenAnnouncementId ??
                    lastSeenAnnouncementIdValue

                isDesktopNavbarOpen.value =
                    localDB.isDesktopNavbarOpen ?? isDesktopNavbarOpenValue

                return
            }
        }

        persist({
            theme: themeValue,
            lastSeenAnnouncementId: lastSeenAnnouncementIdValue,
            isDesktopNavbarOpen: isDesktopNavbarOpenValue,
        })
    }, [
        initializedValue,
        themeValue,
        lastSeenAnnouncementIdValue,
        isDesktopNavbarOpenValue,
    ])

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', themeValue)
    }, [themeValue])
}

export const useTheme = () => theme.value
