import { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useNavigationContext } from 'contexts/navigationContext'

export function useContainerScrollToTop() {
    const router = useRouter()
    const containerRef = useRef(null)
    const shouldScrollToTopRef = useRef(false)
    const { setOverlayScreen } = useNavigationContext()

    const path = router.asPath
    const containerNode = containerRef.current
    const shouldScrollToTop = shouldScrollToTopRef.current

    useEffect(() => {
        const handleRouteChangeStart = url => {
            const isSamePage = path.split('?')[0] === url.split('?')[0]

            if (!isSamePage) {
                shouldScrollToTopRef.current = true
            }
        }

        const handleRouteChangeComplete = () => {
            if (containerNode && shouldScrollToTop) {
                containerNode.scrollTo(0, 0)
                setOverlayScreen(null)
                shouldScrollToTopRef.current = false
            }
        }

        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeComplete)

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
        }
    }, [
        containerNode,
        path,
        shouldScrollToTop,
        setOverlayScreen,
        router.events,
    ])

    return containerRef
}
