import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

// Could use NextJS useSearchParams after migrating to NextJS 13
export function useSearchParams() {
    const router = useRouter()
    const [params, setParams] = useState({})

    useEffect(() => {
        const searchParams = new URLSearchParams(router.asPath.split('?')[1])
        const obj = {}

        for (let [key, value] of searchParams.entries()) {
            obj[key] = value
        }

        setParams(obj)
    }, [router.asPath])

    return params
}
