const {
    ETHEREUM_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    BNB_ECOSYSTEM_KEY,
} = require('./constants')

export const findByEcosystem = tagKeys => {
    return tagKeys?.find(
        ecosystem =>
            ecosystem === ETHEREUM_ECOSYSTEM_KEY ||
            ecosystem === COSMOS_ECOSYSTEM_KEY ||
            ecosystem === POLKADOT_ECOSYSTEM_KEY ||
            ecosystem === BNB_ECOSYSTEM_KEY
    )
}
