import { uiState } from 'state/ui'
import { useEffectOnceOnMount } from './useEffectOnceOnMount'

export function useScrollYParams() {
    useEffectOnceOnMount(() => {
        function handleScroll() {
            const scrollableHeight =
                document.documentElement.scrollHeight - window.innerHeight
            const scrolledDistance = window.scrollY

            uiState.isPageBottom.value = scrolledDistance === scrollableHeight
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
}
