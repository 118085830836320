import { useClientWidthMediaPredicate } from 'hooks'

import styles from './youtubeVideo.module.scss'

export const YoutubeVideo = ({ src = '#', header = '' }) => {
    const isTablet = useClientWidthMediaPredicate(768)
    return (
        <div className={styles.youtubeVideo}>
            {header && <h2>{header}</h2>}
            <div className={styles.video}>
                <iframe
                    width='100%'
                    height={`${isTablet ? 600 : 400}px`}
                    title={header}
                    src={src}
                    allow=''
                    allowFullScreen
                />
            </div>
        </div>
    )
}
