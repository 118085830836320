import classNames from 'classnames'

import styles from './loader.module.scss'

export const Loader = ({ centered = false }) => {
    return (
        <span
            className={`loader ${classNames(styles.loader, {
                [styles.centered]: centered,
            })}`}
        />
    )
}
