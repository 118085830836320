import { useEffect } from 'react'

export function useThrottle(callback, dependency, delay = 500) {
    useEffect(() => {
        if (!callback || !dependency) return

        const handler = setTimeout(callback, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [callback, dependency, delay])
}

//   const [searchTerm, setSearchTerm] = useState("");
//   const [results, setResults] = useState([]);
//   const [isSearching, setIsSearching] = useState(false);

//   useThrottle(
//     () => {
//          setIsSearching(true)
//          getSearchResults(searchTerm).then(res => {
//              setResults(res.data)
//              setIsSearching(false)
//          })
//     },
//     searchTerm, 500
//   );
