import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useTheme } from 'state'
import { useLockBodyScroll } from 'hooks'
import { useNavigationContext } from 'contexts/navigationContext'
import { MobileNavBar } from 'components/navbar'
import { OverlaySearchView } from 'components/menu/overlaySearchView'

import styles from './overlay.module.scss'

const screenToOverlayComponent = {
    search: OverlaySearchView,
    mobileNavbar: MobileNavBar,
}

export function Overlay() {
    const theme = useTheme()
    const { overlayScreen, setOverlayScreen } = useNavigationContext()
    const withDesktopMenu = useMediaPredicate('(min-width: 1280px)')

    const isOverlayOpen = !!overlayScreen

    const OverlayComponent =
        overlayScreen && screenToOverlayComponent[overlayScreen]
            ? screenToOverlayComponent[overlayScreen]
            : null

    useLockBodyScroll(isOverlayOpen)

    return (
        <div
            className={classNames(styles.overlay, {
                [styles.mobileOverlay]: !withDesktopMenu,
                [styles.closeAnimation]: !isOverlayOpen,
            })}
            data-theme={theme}
        >
            <div className='flex flex-col w-full h-full'>
                <div className='flex p-10 justify-end'>
                    <span
                        className='icon icon-close cursor-pointer [--size:18px] [--color:--c-contrast-3]'
                        onClick={() => setOverlayScreen(null)}
                    />
                </div>
                {OverlayComponent ? <OverlayComponent /> : null}
            </div>
        </div>
    )
}
