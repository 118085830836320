import { useEffect, useRef } from 'react'

/**
 * Implements useEffect on componentDidMount, making sure that
 * callbackOnMount is not ran twice. Useful for client-side fetches on mount.
 * Assumes that callbackOnMount does not change as it is not included in the useEffect dependencies.
 */
export const useEffectOnceOnMount = (callbackOnMount = () => {}) => {
    // Prevent callbackOnMount from running twice on mount
    const firstCallMade = useRef(false)
    useEffect(() => {
        if (!firstCallMade?.current) {
            callbackOnMount()
            return () => {
                firstCallMade.current = true
            }
        }
        // Do not include dependencies, run only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
