/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useWatchStorage } from 'state'
import { useNavigationContext } from 'contexts/navigationContext'

export function Initialize() {
    const { setOverlayScreen } = useNavigationContext()

    useWatchStorage()

    useEffect(() => {
        setVh()

        window.addEventListener('resize', setVh)
        window.addEventListener('keydown', handleKeyDown)

        if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', setVh, false)
        }

        return () => {
            window.removeEventListener('resize', setVh)
            window.removeEventListener('keydown', handleKeyDown)

            if ('onorientationchange' in window) {
                window.removeEventListener('orientationchange', setVh)
            }
        }
    }, [])

    const setVh = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--v-height', `${vh}px`)
    }

    const handleMenuToggle = e => {
        if (e.key === '/') {
            setOverlayScreen('search')
        } else if (e.key === 'Escape') {
            setOverlayScreen(null)
        }
    }

    const handleKeyDown = e => {
        switch (e.target.tagName.toLowerCase()) {
            case 'input':
            case 'textarea':
                if (e.key === 'Escape') {
                    document.activeElement.blur()
                    // "Escape" always closes the menu
                    setOverlayScreen(null)
                }
                break
            default:
                handleMenuToggle(e)
                break
        }
    }

    return null
}
