import { useState } from 'react'
import { validateEmail } from 'utils/actions'

export function useForm() {
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [status, setStatus] = useState('idle') //idle, ready, or fetching

    const onChange = e => {
        let name = e.target.name
        let val = e.target.value

        if (val.trim().length === 0) {
            val = ''
        }

        setData({
            ...data,
            [name]: val.trimStart(),
        })

        if (name === 'email') {
            if (val.trim().length === 0)
                return setErrors({ ...errors, email: null })
            let valid = validateEmail(val)
            if (valid) return setErrors({ ...errors, email: false })
            return setErrors({
                ...errors,
                email: 'Please enter a valid email.',
            })
        }
    }

    return {
        data,
        setData,
        errors,
        setErrors,
        status,
        setStatus,
        onChange,
    }
}
