import classNames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { Delimiter } from './delimiter'
import { NavBarItem } from './navbarItem'
import { StateNavbarItem } from './stateNavbarItem'
import { SearchNavbarItem } from './searchNavbarItem'
import { ThemeNavbarItem } from './themeNavbarItem'
import { useNavigationContext } from 'contexts/navigationContext'

export function DesktopNavBar() {
    const { isDesktopNavbarOpen } = useNavigationContext()

    return (
        <div
            className={classNames(
                'flex flex-col justify-between py-2 border-r-2 border-solid border-black/10 dark:border-white/10',
                'transition-[max-width]',
                isDesktopNavbarOpen ? 'max-w-[145px]' : 'max-w-[64px]'
            )}
        >
            <StateNavbarItem />
            <div className='flex flex-col items-start'>
                <NavBarItem
                    title='Discover'
                    iconClassName='icon-discover'
                    link='/assets'
                    activeLinks={['/asset', '/providers', '/provider']}
                />
                <NavBarItem
                    title='Swap'
                    iconClassName='icon-swap'
                    link='/swap'
                />
                <NavBarItem
                    title='Stake'
                    iconClassName='icon-coins'
                    link='/stake-app'
                />
                <NavBarItem
                    title='Optimize'
                    iconClassName='icon-chart-pie'
                    link='/terminal'
                />
                <Delimiter />
                <SearchNavbarItem />
            </div>
            <div className='flex flex-col items-start'>
                <NavBarItem
                    title='API'
                    iconClassName='icon-api-letter'
                    link='/staking-data-api'
                />
                <NavBarItem
                    title='Summit'
                    iconClassName='icon-summit'
                    link='/summit'
                />
                <Delimiter />
                <ThemeNavbarItem />
            </div>
            {!isDesktopNavbarOpen ? (
                <Tooltip
                    id='navbar-item-tooltip'
                    place='right'
                    opacity={1}
                    className='!bg-contrast-1 !text-sm !text-contrast-6 !px-4 !py-3 !rounded-lg z-10'
                />
            ) : null}
        </div>
    )
}
