import { useRef } from 'react'
import classNames from 'classnames'
import { useClickOutside } from 'hooks'
import { decodeHtmlEntities } from 'utils/actions'
import { Link } from 'components/ui'

import styles from './shareTooltip.module.scss'

const VIA = 'StakingRewards'

const generateTwitterUrl = (
    link = '',
    name = '',
    hashtags = [],
    metricsText = ''
) => {
    const text = `${name} Staking Data via @${VIA}\n\n${metricsText}`
    return `https://twitter.com/intent/tweet?&text=${encodeURIComponent(
        decodeHtmlEntities(text)
    )}&hashtags=${hashtags.filter(Boolean)}&via=${VIA}&url=${decodeHtmlEntities(
        encodeURI(link)
    )}`
}

const generateTelegramUrl = (link = '', name = '') => {
    return `https://t.me/share/url?url=${encodeURI(
        link
    )}&text=${decodeHtmlEntities(`${name} Staking Data via @${VIA}`)}`
}

const generateLinkedinUrl = (link = '', name = '') => {
    const text = `${name} Staking Data via @${VIA}`

    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
        link
    )}&title=${decodeHtmlEntities(text)}&source=${encodeURI(link)}`
}

export const ShareTooltip = ({
    pageLink = '',
    hashtags = [],
    metricsText = '',
    name = '',
    forceShow = false,
    onClose = () => {},
    children,
}) => {
    const ref = useRef(null)
    useClickOutside(ref, () => {
        onClose()
    })

    const mediaList = [
        {
            icon: 'telegram',
            className: styles.telegram,
            href: generateTelegramUrl(pageLink, name),
        },
        {
            icon: 'twitter',
            className: styles.twitter,
            href: generateTwitterUrl(pageLink, name, hashtags, metricsText),
        },
        {
            icon: 'linkedin',
            className: styles.linkedIn,
            href: generateLinkedinUrl(pageLink, name),
        },
    ]

    return (
        <div
            ref={ref}
            className={classNames(styles.tooltip, {
                [styles.hover]: forceShow,
            })}
        >
            <div className={classNames(styles.icons)}>
                {mediaList.map((obj, idx) => (
                    <Link key={`${obj?.icon}-${idx}`} blank href={obj?.href}>
                        <div className={styles.iconWrap}>
                            <span
                                className={classNames(
                                    `icon icon-${obj?.icon}`,
                                    styles.icon,
                                    obj?.className
                                )}
                            />
                        </div>
                    </Link>
                ))}
            </div>
            {children}
        </div>
    )
}
