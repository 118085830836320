import { useEffect, useState } from 'react'
import { getTimeAgo } from 'utils/converter'

export const useTimeElapsed = (updatedAt = '') => {
    const [timeElapsed, setTimeElapsed] = useState(
        updatedAt ? getTimeAgo(updatedAt, true) : null
    )

    // Reset on each change of initial time
    useEffect(() => {
        setTimeElapsed(updatedAt ? getTimeAgo(updatedAt, true) : null)
    }, [updatedAt])

    // Reset every 60 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeElapsed(updatedAt ? getTimeAgo(updatedAt, true) : null)
        }, 60000)

        return () => {
            clearInterval(interval)
        }
    }, [updatedAt])

    return timeElapsed
}
