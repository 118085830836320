import { TYPE_ASSET, TYPE_PROVIDER, TYPE_VALIDATOR } from 'utils/constants'
import { ellipseString } from 'utils/formatter'

import { ComponentWithLink } from 'components/ui/links/link'
import { Loader } from 'components/ui/loader'

import styles from './stackedItems.module.scss'
import { GroupSize, ItemWithLogo } from 'components/ui/itemWithLogo'

const ItemWithLink = ({
    name = '',
    href = '',
    iconUrl = '',
    ticker = '',
    slug = '',
    blank = false,
    isVerified = false,
}) => {
    return (
        <ComponentWithLink link={href} blank={blank}>
            <ItemWithLogo
                className={styles.item}
                iconUrl={iconUrl}
                name={name}
                details={ticker}
                isVerified={isVerified}
                alt={`${name} ${ticker}`}
                slug={slug}
                iconSize={36}
                sizeType={GroupSize.Small}
                withDetails={true}
            />
        </ComponentWithLink>
    )
}

export const StackedItems = ({
    data = null,
    type = TYPE_ASSET,
    isLoading = false,
    emptyMessage,
}) => {
    const noResults = !data?.length
    return (
        <div className={styles.stackedItems}>
            {isLoading ? (
                <Loader />
            ) : noResults ? (
                <p>{emptyMessage ?? 'There are no results for your search.'}</p>
            ) : (
                data?.map((item, idx) => {
                    const fullName =
                        type === TYPE_VALIDATOR ? item?.address : item?.name
                    const name =
                        String(fullName).length > 20
                            ? ellipseString(fullName)
                            : fullName

                    if (type === TYPE_ASSET) {
                        return (
                            <ItemWithLink
                                key={`list-${TYPE_ASSET}-${item?.slug}-${idx}`}
                                iconUrl={item?.logoUrl || item?.logo_url}
                                href={`/asset/${item?.slug}`}
                                name={name}
                                ticker={item?.symbol}
                                blank={false}
                                slug={item?.slug}
                            />
                        )
                    }

                    if (type === TYPE_PROVIDER) {
                        return (
                            <ItemWithLink
                                key={`list-${TYPE_PROVIDER}-${item?.slug}-${idx}`}
                                iconUrl={item?.logoUrl || item?.logo_url}
                                href={
                                    item?.isClaimed
                                        ? `/provider/${item?.slug}`
                                        : 'https://stakingrewards.typeform.com/claim-profile'
                                }
                                name={name}
                                ticker={
                                    item?.isClaimed ? '' : 'Claim Your Profile'
                                }
                                blank={!item?.isClaimed}
                                slug={item?.slug}
                                isVerified={item?.isVerified}
                            />
                        )
                    }

                    if (type === TYPE_VALIDATOR) {
                        return (
                            <ItemWithLink
                                key={`list-${TYPE_VALIDATOR}-${item?.address}-${idx}`}
                                iconUrl={null}
                                href={
                                    'https://stakingrewards.typeform.com/claim-profile'
                                }
                                name={name}
                                ticker={'Claim Your Profile'}
                                blank={true}
                                slug={item?.address}
                            />
                        )
                    }
                })
            )}
        </div>
    )
}
