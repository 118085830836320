import classNames from 'classnames'
import { useState, useRef } from 'react'
import { useClickOutside } from 'hooks'
import { NO_DATA_INDICATOR } from 'utils/formatter'

import styles from './dropdownSelect.module.scss'

export function DropdownSelect({
    className = '',
    choices = [],
    selected = null,
    onSelect = () => {},
    renderItem = () => {},
    renderSelected = () => {},
    small = false,
}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    useClickOutside(dropdownRef, () => setIsOpen(false))

    return (
        <div
            className={classNames(styles.dropdown, className, {
                [styles.small]: small,
            })}
            ref={dropdownRef}
        >
            <div
                className={classNames(styles.selected, {
                    [styles.hidden]: isOpen,
                })}
                onClick={() => {
                    if ((choices?.length ?? 0) > 0) {
                        setIsOpen(!isOpen)
                    }
                }}
            >
                <div className={styles.name}>
                    {renderSelected?.(selected) ??
                        selected?.name ??
                        NO_DATA_INDICATOR}
                </div>
                {(choices?.length ?? 0) > 0 && (
                    <span
                        className={classNames(
                            `icon icon-og icon-chevron-arrow-down`,
                            styles.arrow
                        )}
                    />
                )}
            </div>
            <div
                className={classNames(styles.options, {
                    [styles.hidden]: !isOpen,
                })}
            >
                <div className='flex flex-col w-full'>
                    <div
                        key={`choice-${selected?.key}-selected`}
                        className={`flex flex-row justify-between items-center w-full whitespace-nowrap truncate cursor-pointer px-3 py-1 font-normal ${styles.selectedOpen}`}
                        onClick={() => {
                            onSelect(selected)
                            setIsOpen(false)
                        }}
                    >
                        <div className={styles.name}>
                            {renderSelected?.(selected) ??
                                selected?.name ??
                                NO_DATA_INDICATOR}
                        </div>
                        <span
                            className={classNames(
                                `icon icon-og icon-chevron-arrow-up`,
                                styles.arrow,
                                styles.arrowUp
                            )}
                        />
                    </div>
                    <ul>
                        {choices.map((choice, idx) => (
                            <li
                                key={`choice-${choice?.key}-${idx}`}
                                className={classNames({
                                    [styles.selectedOption]:
                                        choice?.key === selected?.key,
                                })}
                                onClick={() => {
                                    onSelect(choice)
                                    setIsOpen(false)
                                }}
                            >
                                {renderItem?.(choice) ??
                                    choice?.name ??
                                    NO_DATA_INDICATOR}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
