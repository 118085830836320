import { signal } from '@preact/signals-react'

const firstLoad = signal(true)
const scrollDir = signal(1)
const scrolled = signal(false)
const isPageBottom = signal(false)

export const uiState = {
    firstLoad,
    scrollDir,
    scrolled,
    isPageBottom,
}
