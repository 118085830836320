import {
    isInvalidNumber,
    EMPTY_STAKING_TIME_OBJECT,
    NO_DATA_INDICATOR,
} from 'utils/formatter'

export const convertTime = (
    prevTime = 0,
    prevTimeUnit = 'days',
    newTimeUnit = 'days'
) => {
    if (!isInvalidNumber(prevTime)) {
        const initialTime = parseFloat(prevTime)

        switch (prevTimeUnit) {
            case 'days':
                switch (newTimeUnit) {
                    case 'months':
                        return initialTime / 30
                    case 'years':
                        return initialTime / 365
                    case 'hours':
                        return initialTime * 24
                    case 'minutes':
                        return initialTime * 1440
                    case 'seconds':
                        return initialTime * 86400
                }
                break
            case 'months':
                switch (newTimeUnit) {
                    case 'days':
                        return initialTime * 30
                    case 'years':
                        return initialTime / 12
                    case 'hours':
                        return initialTime * 720
                    case 'minutes':
                        return initialTime * 43200
                    case 'seconds':
                        return initialTime * 2592000
                }
                break
            case 'years':
                switch (newTimeUnit) {
                    case 'days':
                        return initialTime * 365
                    case 'months':
                        return initialTime * 12
                    case 'hours':
                        return initialTime * 8760
                    case 'minutes':
                        return initialTime * 525600
                    case 'seconds':
                        return initialTime * 31536000
                }
                break
            case 'hours':
                switch (newTimeUnit) {
                    case 'days':
                        return initialTime / 24
                    case 'months':
                        return initialTime / 720
                    case 'years':
                        return initialTime / 8760
                    case 'minutes':
                        return initialTime * 60
                    case 'seconds':
                        return initialTime * 3600
                }
                break
            case 'minutes':
                switch (newTimeUnit) {
                    case 'days':
                        return initialTime / 1440
                    case 'months':
                        return initialTime / 43200
                    case 'years':
                        return initialTime / 525600
                    case 'hours':
                        return initialTime / 60
                    case 'seconds':
                        return initialTime * 60
                }
                break
            case 'seconds':
                switch (newTimeUnit) {
                    case 'days':
                        return initialTime / 86400
                    case 'months':
                        return initialTime / 2592000
                    case 'years':
                        return initialTime / 31536000
                    case 'hours':
                        return initialTime / 3600
                    case 'minutes':
                        return initialTime / 60
                }
                break
        }
    }

    return prevTime
}

// Converting explicitly since 12 months * 30 days != 365 days * 1 year to unify
export const timeObjectToDays = (
    timeDurationObject = EMPTY_STAKING_TIME_OBJECT
) => {
    const yearsToDays = !isInvalidNumber(timeDurationObject?.years)
        ? convertTime(timeDurationObject?.years, 'years', 'days')
        : 0
    const monthsToDays = !isInvalidNumber(timeDurationObject?.months)
        ? convertTime(timeDurationObject?.months, 'months', 'days')
        : 0
    const days = !isInvalidNumber(timeDurationObject?.days)
        ? timeDurationObject?.days
        : 0

    return yearsToDays + monthsToDays + days
}

export const timeObjectToMonths = (
    timeDurationObject = EMPTY_STAKING_TIME_OBJECT
) => {
    const yearsToMonths = !isInvalidNumber(timeDurationObject?.years)
        ? convertTime(timeDurationObject?.years, 'years', 'months')
        : 0
    const months = !isInvalidNumber(timeDurationObject?.months)
        ? timeDurationObject?.months
        : 0
    const daysToMonths = !isInvalidNumber(timeDurationObject?.days)
        ? convertTime(timeDurationObject?.days, 'days', 'months')
        : 0

    return yearsToMonths + months + daysToMonths
}

export const timeObjectToYears = (
    timeDurationObject = EMPTY_STAKING_TIME_OBJECT
) => {
    const years = !isInvalidNumber(timeDurationObject?.years)
        ? timeDurationObject?.years
        : 0
    const monthsToYears = !isInvalidNumber(timeDurationObject?.months)
        ? convertTime(timeDurationObject?.months, 'months', 'years')
        : 0
    const daysToYears = !isInvalidNumber(timeDurationObject?.days)
        ? convertTime(timeDurationObject?.days, 'days', 'years')
        : 0

    return years + monthsToYears + daysToYears
}

const timeIntervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
}

const getAgoString = (
    interval = null,
    timeDiffInSeconds = 0,
    isPrecise = true
) => {
    if (interval) {
        const timeAgoValue = Math.floor(
            timeDiffInSeconds / timeIntervals[interval]
        )

        const pluralSuffix = timeAgoValue === 1 ? '' : 's'

        if (isPrecise) {
            return `${timeAgoValue} ${interval}${pluralSuffix} ago`
        }

        if (interval === 'day' && timeAgoValue === 1) {
            return 'yesterday'
        }

        return `${timeAgoValue} ${interval}${pluralSuffix} ago`
    }

    return isPrecise ? 'just now' : 'today'
}

export const getTimeAgo = (createdAt = '', isPrecise = true) => {
    const currentDate = new Date()
    const providedDate = new Date(createdAt)
    const timeDiffInSeconds = Math.floor((currentDate - providedDate) / 1000)

    // Find the largest time unit (the order of time interval keys matters)
    const intervalKeys = isPrecise
        ? Object.keys(timeIntervals)
        : Object.keys(timeIntervals).filter(
              key => !['hour', 'minute'].includes(key)
          )
    const interval = intervalKeys.find(intervalKey => {
        const timeAgoValue = Math.floor(
            timeDiffInSeconds / timeIntervals[intervalKey]
        )
        return timeAgoValue >= 1
    })

    return getAgoString(interval, timeDiffInSeconds, isPrecise)
}

export const isoDateToEuropeanDate = (isoDate = '') => {
    let dateParts = isoDate.split('T')[0].split('-')
    if (dateParts?.length !== 3) {
        return NO_DATA_INDICATOR
    }
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
}

export const getLatestNetFlowMetricPerDays = (allMetrics = []) => {
    const latestMetricsPerDaysObj = allMetrics.reduce(
        (accumulator, currObj) => {
            const currentDate = new Date(currObj?.createdAt ?? null)
            const currentDateString = currentDate.toISOString().slice(0, 10)

            const existingObj = accumulator[currentDateString]

            if (
                !existingObj ||
                new Date(currObj.createdAt) > new Date(existingObj.createdAt)
            ) {
                accumulator[currentDateString] = currObj
            }

            return accumulator
        },
        {}
    )
    const latestMetricsPerDays = Object.values(latestMetricsPerDaysObj)

    const sortedLatestMetricsPerDays = latestMetricsPerDays
        ?.map(m => {
            const metricDate = new Date(m?.createdAt?.slice(0, 10))
            const now = new Date()
            const onlyDateNow = new Date(
                Date.UTC(
                    now.getUTCFullYear(),
                    now.getUTCMonth(),
                    now.getUTCDate()
                )
            )
            const diffInMs = Math.abs(onlyDateNow - metricDate)
            const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
            return { daysDiff: diffInDays, metric: m }
        })
        ?.sort((a, b) => a.daysDiff - b.daysDiff)

    return sortedLatestMetricsPerDays
}
