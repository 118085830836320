export const TYPE_ASSET = 'asset'
export const TYPE_PROVIDER = 'provider' // used for reward options as well
export const TYPE_VALIDATOR = 'validator'

export const ALL_KEY = 'all'

export const ALL_CATEGORY = {
    key: ALL_KEY,
    name: 'All',
    title: 'Show All',
}

export const BABYLON_MIN_STAKE_AMOUNT = 0.005
export const BABYLON_MAX_STAKE_AMOUNT = 500
export const LOMBARD_MIN_STAKE_AMOUNT = 0.0002

export const BITCOIN_WALLETS = [
    {
        label: 'Taproot',
        isSupported: true,
        prefix: 'bc1p',
    },
    {
        label: 'Native segwit',
        isSupported: true,
        prefix: 'bc1',
    },
    {
        label: 'Nested segwit',
        isSupported: false,
        prefix: '3',
    },
    {
        label: 'Legacy',
        isSupported: false,
        prefix: '1',
    },
]

export const DROP_WHITELISTED_VALIDATORS = [
    'chorus-one',
    'provalidator',
    'stakecito',
    'cosmostation',
    'p2p-validator',
]

export const POS_STAKING_KEY = 'pos'
export const LIQUID_STAKING_KEY = 'liquid-staking'
export const AVS_KEY = 'actively-validated-service'
export const STABLECOIN_KEY = 'stablecoin'
export const BITCOIN_AND_OTHERS_KEY = 'bitcoin-and-others'
export const LIQUID_RESTAKING_KEY = 'liquid-restaking'
export const NATIVE_RESTAKING_KEY = 'dual-staking'
export const BABYLON_STAKING_KEY = 'babylon-staking'
export const LAVA_POS_STAKING_KEY = 'pos'
export const LAVA_SLUG = 'lava'
export const BITCOIN_SLUG = 'bitcoin'

export const ETHEREUM_ECOSYSTEM_KEY = 'ethereum-ecosystem'
export const COSMOS_ECOSYSTEM_KEY = 'cosmos-ecosystem'
export const POLKADOT_ECOSYSTEM_KEY = 'polkadot-ecosystem'
export const BNB_ECOSYSTEM_KEY = 'binance-smart-chain-ecosystem'

export const WALLET_NAME_SLUG = {
    'leap-extension': 'LEAP',
    'keplr-extension': 'KEPLR',
}

export const POS_ASSET_CATEGORY = {
    key: 'proof-of-stake',
    name: 'Proof of Stake',
    slug: null,
}

export const STABLECOINS_ASSET_CATEGORY = {
    key: 'stablecoins',
    name: 'Stablecoins',
    slug: null,
}

export const DEFAULT_ASSET_CATEGORY = POS_ASSET_CATEGORY

export const ASSET_CATEGORIES = [
    { ...ALL_CATEGORY, slug: null },
    POS_ASSET_CATEGORY,
    { key: LIQUID_STAKING_KEY, name: 'Liquid (Re)Staking', slug: null },
    { key: AVS_KEY, name: 'Actively Validated Services', slug: null },
    { key: STABLECOIN_KEY, name: 'Stablecoins', slug: null },
    { key: BITCOIN_AND_OTHERS_KEY, name: 'Bitcoin & Others', slug: null },
    { key: ETHEREUM_ECOSYSTEM_KEY, name: 'Ethereum', slug: 'ethereum-2-0' },
    { key: COSMOS_ECOSYSTEM_KEY, name: 'Cosmos', slug: 'cosmos' },
    // { key: 'near-protocol-ecosystem', name: 'Near', slug: 'near-protocol' },
    { key: POLKADOT_ECOSYSTEM_KEY, name: 'Polkadot', slug: 'polkadot' },
    {
        key: BNB_ECOSYSTEM_KEY,
        name: 'BNB Chain',
        slug: 'binance-smart-chain',
    },
    // { key: 'avalanche-ecosystem', name: 'Avalanche', slug: 'avalanche' },
    // { key: 'polygon-ecosystem', name: 'Polygon', slug: 'matic-network' },
    // { key: 'tron-ecosystem', name: 'Tron', slug: 'tron' },
    // { key: 'chronos-ecosystem', name: 'Cronos', slug: 'cronos' },
    // { key: 'fantom-ecosystem', name: 'Fantom', slug: 'fantom' },
]

const TIMEFRAME_7D_CHOICE = { key: '7d', name: '7d' }
const TIMEFRAME_30D_CHOICE = { key: '30d', name: '30d' }

export const DEFAULT_ASSET_TIMEFRAME = TIMEFRAME_7D_CHOICE
export const DEFAULT_PROVIDER_TIMEFRAME = TIMEFRAME_30D_CHOICE
export const DEFAULT_REWARD_OPTION_TIMEFRAME = TIMEFRAME_30D_CHOICE

export const TIMEFRAME_CHOICES = [
    { key: '24h', name: '24h' },
    TIMEFRAME_7D_CHOICE,
    TIMEFRAME_30D_CHOICE,
    { key: '90d', name: '90d' },
    { key: '1y', name: '1y' },
]

export const DEFAULT_ORDER = { key: 'desc', name: 'Desc' }

export const ORDER_CHOICES = [DEFAULT_ORDER, { key: 'asc', name: 'Asc' }]

export const POS_PROVIDER_KEY = 'pos'
export const LIQUID_STAKING_PROVIDER_KEY = LIQUID_STAKING_KEY
export const LENDING_PROVIDER_KEY = 'lending'
export const CUSTODIAL_PROVIDER_KEY = 'custodial'
export const HOSTING_KEY = 'hosting'
export const SOLO_STAKING_KEY = 'solo-staking'
export const SMART_CONTRACT_KEY = 'smart-contract'
export const OPERATOR_KEY = 'operator'
export const RESTAKING_KEY = 'restaking'
export const PARTIAL_STAKING_KEY = 'partial-staking'

export const ALL_PROVIDER_CATEGORIES = [
    {
        ...ALL_CATEGORY,
        withProvider: true,
        hasStakedTokens: true,
    },
    {
        key: NATIVE_RESTAKING_KEY,
        name: 'Native Restaking',
        withProvider: false,
        hasStakedTokens: true,
    },
    {
        key: POS_PROVIDER_KEY,
        name: 'Native Staking',
        withProvider: true,
        hasStakedTokens: true,
    },
    {
        key: LIQUID_STAKING_PROVIDER_KEY,
        name: 'Liquid (Re)Staking',
        withProvider: true,
        hasStakedTokens: true,
    },
    {
        key: LENDING_PROVIDER_KEY,
        name: 'Lending',
        withProvider: true,
        hasStakedTokens: true,
    },
    {
        key: CUSTODIAL_PROVIDER_KEY,
        name: 'Custodial',
        withProvider: true,
        hasStakedTokens: true,
    },
    {
        key: HOSTING_KEY,
        name: 'Validator as a Service',
        withProvider: true,
        hasStakedTokens: false,
    },
    {
        key: SOLO_STAKING_KEY,
        name: 'Solo Staking',
        withProvider: false,
        hasStakedTokens: false,
    },
    {
        key: SMART_CONTRACT_KEY,
        name: 'Smart Contract Staking',
        withProvider: false,
        hasStakedTokens: true,
    },
]

export const DEFAULT_PROVIDER_CATEGORY = ALL_PROVIDER_CATEGORIES.find(
    category => category.key === POS_PROVIDER_KEY
)

export const DEFAULT_REWARD_OPTION_CATEGORY = DEFAULT_PROVIDER_CATEGORY

// Categories used for rewards options
// For calculator:
export const PROVIDER_CATEGORIES = ALL_PROVIDER_CATEGORIES.filter(
    category => ![ALL_KEY, CUSTODIAL_PROVIDER_KEY].includes(category.key)
)
// For profiles:
export const REWARD_OPTION_CATEGORIES = [
    ...ALL_PROVIDER_CATEGORIES,
    {
        key: OPERATOR_KEY,
        name: 'Operator',
        withProvider: true,
        hasStakedTokens: true,
        title: 'Operator',
    },
    {
        key: PARTIAL_STAKING_KEY,
        name: 'Partial Staking',
        withProvider: true,
        hasStakedTokens: true,
        title: 'Partial Staking',
    },
    {
        key: AVS_KEY,
        name: 'Restaking',
        withProvider: true,
        hasStakedTokens: true,
        title: 'AVS',
    },
    {
        key: 'babylon-staking',
        name: 'Babylon Staking',
        withProvider: true,
        hasStakedTokens: true,
        title: 'Babylon Staking',
    },
    {
        key: RESTAKING_KEY,
        name: 'Restaking',
        withProvider: true,
        hasStakedTokens: true,
    },
]

// Categories used for provider archive
export const PROVIDER_ARCHIVE_CATEGORIES = ALL_PROVIDER_CATEGORIES.filter(
    category =>
        ![
            SMART_CONTRACT_KEY,
            CUSTODIAL_PROVIDER_KEY,
            LENDING_PROVIDER_KEY,
            HOSTING_KEY,
        ].includes(category.key)
)

export const PAGE_SIZE_10 = 10
export const PAGE_SIZE_20 = 20

export const MAX_QUERY_LIMIT = 500

export const PageSizes = {
    Five: 5,
    Ten: 10,
    Twenty: 20,
    Fifty: 50,
    Hundred: 100,
}

export const PageSizeChoices = [
    { key: PageSizes.Five, name: '5' },
    { key: PageSizes.Ten, name: '10' },
    { key: PageSizes.Twenty, name: '20' },
    { key: PageSizes.Fifty, name: '50' },
    { key: PageSizes.Hundred, name: '100' },
]

export const UNDER_MAINTENANCE = 'Under Maintenance'
export const TRENDING = 'Trending'

export const DISCORD_LINK = 'https://discord.com/invite/HAUD5j3jH6'

export const STAKING_RISK_RATINGS = [
    { key: '1', letter: 'A', color: 'var(--c-green)' },
    { key: '2', letter: 'B', color: 'var(--c-yellow-green)' },
    { key: '3', letter: 'C', color: 'var(--c-yellow)' },
    { key: '4', letter: 'D', color: 'var(--c-orange)' },
    { key: '5', letter: 'E', color: 'var(--c-red)' },
]

export const ASSETS_UNDER_MANAGEMENT_METRIC_KEY = 'assets_under_management'

export const CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY =
    'custom_net_staking_flow_7d'

export const CUSTOM_AUM_CHANGE_METRIC_KEY = 'custom_aum_change'

export const TIME_RANGES = [
    {
        name: '7d',
        dayCount: 7,
    },
    { name: '30d', dayCount: 30 },
    { name: '90d', dayCount: 90 },
    { name: '1y', dayCount: 365 },
]

export const MailerLiteGroupId = {
    API: '115508246017475656',
    StakingInsider: '115508082294916785',
    Maintenance: '115590017215104659',
    BabylonCap2: '133915995560478350',
    StarknetWaitlist: `135180464480585395`,
}

export const RewardOptionType = {
    PROOF_OF_STAKE: 'pos',
    LIQUID_STAKING: 'liquid-staking',
    DEFI: 'smart-contract',
    SOLO_STAKING: 'solo-staking',
    SMART_CONTRACT: 'smart-contract',
    RESTAKING: 'restaking',
    BORROWING: 'borrowing',
    LIQUIDITY_POOL: 'liquidity-pool',
    LENDING: 'lending',

    CUSTODIAL: 'custodial',
    HOSTING: 'hosting',
    RUN_VALIDATOR: 'run-validator',
    BABYLON_STAKING: 'babylon-staking',
    NATIVE_RESTAKING: 'dual-staking',
    PARTIAL_STAKING: 'partial-staking',
}

export const deFiTypes = [
    RewardOptionType.SMART_CONTRACT,
    RewardOptionType.BORROWING,
    RewardOptionType.LENDING,
    RewardOptionType.LIQUIDITY_POOL,
    RewardOptionType.RESTAKING,
]

export const EXTERNAL_ARROW = '↗'

export const WalletStatus = {
    Disconnected: 'Disconnected',
    Connecting: 'Connecting',
    Connected: 'Connected',
    NotExist: 'NotExist',
    Rejected: 'Rejected',
    Error: 'Error',
}
