import { NavBarItem } from './navbarItem'
import { useNavigationContext } from 'contexts/navigationContext'

export function SearchNavbarItem() {
    const { overlayScreen, setOverlayScreen } = useNavigationContext()

    return (
        <NavBarItem
            title='Search'
            iconClassName='icon-search'
            onClick={() => setOverlayScreen(overlayScreen ? null : 'search')}
        />
    )
}
