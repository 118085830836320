import { computed, signal } from '@preact/signals-react'
import { LoginType } from 'utils/stakingAssistant/constants'

const currentWallet = signal(null)
const allWallets = signal([])
const connectionType = signal(null)
const walletModalIsOpen = signal(false)

const walletAddress = computed(() => currentWallet.value?.address)
const walletIcon = computed(() => currentWallet.value?.icon)

const isConenctedToDynamic = signal(false)
/*
 * @deprecated
 */
const isWalletConnected = computed(
    () => walletAddress.value && connectionType.value === LoginType.WALLET
)

const allWalletAddresses = computed(
    () => allWallets.value?.map(w => w.address).filter(Boolean) ?? []
)

const logout = () => {
    window.localStorage.removeItem('address')
    window.localStorage.removeItem('addressENS')
    currentWallet.value = null
    allWallets.value = []
}

export const WalletState = {
    currentWallet,
    walletAddress,
    walletIcon,
    allWallets,
    allWalletAddresses,
    connectionType,
    isWalletConnected,
    isConenctedToDynamic,
    walletModalIsOpen,
    logout,
}
