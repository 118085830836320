import { useEffect, useState } from 'react'
import { useIsVisible } from './useIsVisible'

/* TODO: SEO optimization:
Use this hook to prevent loading resource-intensive components all at once before they become visible. 
As a prerequisite, each component should display a skeleton when its occupied area is not in view.
*/
export const useDeferredRendering = ref => {
    const isVisible = useIsVisible(ref)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (isVisible && !isReady) {
            setIsReady(true)
        }
    }, [isVisible, isReady])

    return isReady
}
