import {
    convertTime,
    timeObjectToDays,
    timeObjectToMonths,
    timeObjectToYears,
} from 'utils/converter'
import {
    isInvalidNumber,
    DECIMALS_LIMIT_DEFAULT,
    EMPTY_STAKING_TIME_OBJECT,
    DECIMAL_SEPARATOR,
    formatOutputNumber,
    formatInputNumber,
    countDecimalPlaces,
} from 'utils/formatter'

// Validate strings like ".", "-" and "123." as valid if decimals and negative are allowed
export const isIncompleteValidTyping = (
    inputValue = '',
    allowsDecimal = true,
    allowsNegative = true,
    decimalLimit = DECIMALS_LIMIT_DEFAULT
) => {
    const newValue = String(inputValue)

    // "."
    const isAllowedDecimalSeparator =
        newValue === DECIMAL_SEPARATOR && allowsDecimal

    // Values like "123."
    const endsWithDecimalSeparator = newValue.endsWith(DECIMAL_SEPARATOR)
    // Values like "123.0..."
    const endsWithDecimalSeparatorAndZero = new RegExp(
        `\\${DECIMAL_SEPARATOR}0+$`
    ).test(newValue)

    const alreadyHasDecimalSeparator = newValue
        .slice(0, newValue.length - 1)
        .includes(DECIMAL_SEPARATOR)
    const isIncompleteDecimalNumber =
        ((endsWithDecimalSeparator && !alreadyHasDecimalSeparator) ||
            endsWithDecimalSeparatorAndZero) &&
        allowsDecimal

    // "-"
    const isAllowedMinus = newValue === '-' && allowsNegative

    // Zero values like "0.00" shouldn't be converted to 0 while typing
    const isSmallDecimal =
        alreadyHasDecimalSeparator &&
        Number(newValue) === 0 &&
        allowsDecimal &&
        countDecimalPlaces(newValue) < decimalLimit

    return (
        isAllowedDecimalSeparator ||
        isIncompleteDecimalNumber ||
        isAllowedMinus ||
        isSmallDecimal
    )
}

export const replaceTypedCommaWithDot = (
    originalInputValue = '',
    typedInputValue = ''
) => {
    let changedPosition = null
    for (
        let i = 0;
        i <
        Math.max(originalInputValue?.length ?? 0, typedInputValue?.length ?? 0);
        i++
    ) {
        if (originalInputValue?.[i] !== typedInputValue?.[i]) {
            changedPosition = i
            break
        }
    }

    if (
        changedPosition !== null &&
        typedInputValue?.[changedPosition] === ',' &&
        !originalInputValue.includes(DECIMAL_SEPARATOR)
    ) {
        const part1 = typedInputValue.substring(0, changedPosition)
        const part2 = typedInputValue.substring(changedPosition + 1)
        return part1 + DECIMAL_SEPARATOR + part2
    }

    return typedInputValue
}

export const validateWithinMinMaxRange = (
    inputValue = '',
    minValue = null,
    maxValue = null,
    decimalLimit = 0,
    setInputValue = () => {},
    setStateValue = () => {},
    isSubmitting = false
) => {
    if (isInvalidNumber(inputValue)) return

    const inputPrecision = Math.min(
        countDecimalPlaces(inputValue),
        decimalLimit
    )

    // If min and max values are not defined, set the input value without validation
    if (isInvalidNumber(minValue) || isInvalidNumber(maxValue)) {
        setInputValue(
            formatOutputNumber(inputValue, {
                precision: inputPrecision,
                allowEmpty: true,
                withAbbreviation: false,
                forcePrecision: true,
                showApproximation: false,
            })
        )
        setStateValue(formatInputNumber(Number(inputValue), inputPrecision))
    }

    if (inputValue <= maxValue) {
        if (inputValue >= minValue) {
            // Save a number to the state and a formattedString to the input
            setInputValue(
                formatOutputNumber(inputValue, {
                    precision: inputPrecision,
                    allowEmpty: true,
                    withAbbreviation: false,
                    forcePrecision: true,
                    showApproximation: false,
                })
            )
            setStateValue(formatInputNumber(Number(inputValue), inputPrecision))
        } else {
            if (isSubmitting) {
                const minValuePrecision = Math.min(
                    countDecimalPlaces(minValue),
                    decimalLimit
                )
                setInputValue(
                    formatOutputNumber(minValue, {
                        precision: minValuePrecision,
                        allowEmpty: true,
                        withAbbreviation: false,
                        forcePrecision: true,
                        showApproximation: false,
                    })
                )
                setStateValue(
                    formatInputNumber(Number(minValue), minValuePrecision)
                )
            } else {
                // Allow setting a smaller value than minimum value to input when typing
                setInputValue(
                    formatOutputNumber(inputValue, {
                        precision: inputPrecision,
                        allowEmpty: true,
                        withAbbreviation: false,
                        forcePrecision: true,
                        showApproximation: false,
                    })
                )
            }
        }
    } else {
        if (isSubmitting) {
            const maxValuePrecision = Math.min(
                countDecimalPlaces(maxValue),
                decimalLimit
            )
            setInputValue(
                formatOutputNumber(maxValue, {
                    precision: maxValuePrecision,
                    allowEmpty: true,
                    withAbbreviation: false,
                    forcePrecision: true,
                    showApproximation: false,
                })
            )
            setStateValue(
                formatInputNumber(Number(maxValue), maxValuePrecision)
            )
        }
    }
}

export const getLimitForUnit = (
    timeDuration = EMPTY_STAKING_TIME_OBJECT,
    unit = 'days',
    limitDurationObject = EMPTY_STAKING_TIME_OBJECT
) => {
    if (unit === 'years') {
        const limitDurationInYears = timeObjectToYears(limitDurationObject)
        const monthsToYears = !isInvalidNumber(timeDuration?.months)
            ? convertTime(timeDuration.months, 'months', 'years')
            : 0
        const daysToYears = !isInvalidNumber(timeDuration?.days)
            ? convertTime(timeDuration.days, 'days', 'years')
            : 0
        return Math.max(0, limitDurationInYears - (monthsToYears + daysToYears))
    }

    if (unit === 'months') {
        const limitDurationInMonths = timeObjectToMonths(limitDurationObject)
        const yearsToMonths = !isInvalidNumber(timeDuration?.years)
            ? convertTime(timeDuration.years, 'years', 'months')
            : 0
        const daysToMonths = !isInvalidNumber(timeDuration?.days)
            ? convertTime(timeDuration.days, 'days', 'months')
            : 0
        return Math.max(
            0,
            limitDurationInMonths - (yearsToMonths + daysToMonths)
        )
    }

    if (unit === 'days') {
        const limitDurationInDays = timeObjectToDays(limitDurationObject)
        const yearsToDays = !isInvalidNumber(timeDuration?.years)
            ? convertTime(timeDuration.years, 'years', 'days')
            : 0
        const monthsToDays = !isInvalidNumber(timeDuration?.months)
            ? convertTime(timeDuration.months, 'months', 'days')
            : 0
        return Math.max(0, limitDurationInDays - (yearsToDays + monthsToDays))
    }

    return 0
}
