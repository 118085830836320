import { useQueries } from '@tanstack/react-query'
import { getAssetType } from 'components/assets/profile/utils'
import { useNavigationContext } from 'contexts/navigationContext'
import {
    fetchCountRewardOptionsByAssetSlug,
    fetchSelectedAssetProfile,
} from 'data/queries'
import { useParams } from 'next/navigation'
import { useCallback, useEffect } from 'react'
import { Tab } from './tab'
import { TabsContainer } from './tabsContainer'

const getBlacklistedSections = (asset = null, countRewardOptions = null) => {
    const blacklistedSections = new Set()

    const {
        isAVS,
        isStablecoin,
        isBTC,
        isUntracked,
        isPartial,
        isUnderMaintenance,
    } = getAssetType(asset)

    if (isUnderMaintenance) {
        blacklistedSections.add('providers')
        blacklistedSections.add('analytics')
        blacklistedSections.add('calculator')
    } else if (isAVS || isPartial) {
        blacklistedSections.add('calculator')
    } else if (isStablecoin) {
        blacklistedSections.add('analytics')
    } else if (isBTC) {
        blacklistedSections.add('analytics')
    } else if (isUntracked) {
        blacklistedSections.add('providers')
        blacklistedSections.add('analytics')
    }

    if (!countRewardOptions?.length) {
        blacklistedSections.add('providers')
    }

    return Array.from(blacklistedSections)
}

export function AssetTabs() {
    const { selectedSectionId, setSelectedSectionId, pageSections } =
        useNavigationContext()

    const params = useParams()
    const { slug } = params || {}

    const [{ data: asset }, { data: countRewardOptions }] = useQueries({
        queries: [
            {
                queryKey: ['assetProfile', slug],
                queryFn: async () => {
                    const data = await fetchSelectedAssetProfile(slug)
                    return data.assets[0]
                },
                enabled: Boolean(slug),
            },
            {
                queryKey: ['countRewardOption', slug],
                queryFn: async () => {
                    const data = await fetchCountRewardOptionsByAssetSlug(slug)
                    return data.assets[0].rewardOptionsWithAssetAsInput
                },
                enabled: Boolean(slug),
            },
        ],
    })

    const { isUnderMaintenance } = getAssetType(asset)

    useEffect(() => {
        if (
            (countRewardOptions === null &&
                selectedSectionId === 'providers') ||
            isUnderMaintenance
        ) {
            setSelectedSectionId('about')
        }
    }, [
        countRewardOptions,
        selectedSectionId,
        isUnderMaintenance,
        setSelectedSectionId,
    ])

    const handleClick = useCallback(
        id => {
            setSelectedSectionId(id)
        },
        [setSelectedSectionId]
    )

    const blacklistedSections = getBlacklistedSections(
        asset,
        countRewardOptions
    )

    const filteredPageSections = pageSections.filter(
        ({ id }) => !blacklistedSections.includes(id)
    )

    return (
        <TabsContainer>
            {filteredPageSections.map(({ id, title }) => (
                <Tab
                    key={id}
                    id={id}
                    title={title}
                    onClick={handleClick}
                    isActive={selectedSectionId === id}
                />
            ))}
        </TabsContainer>
    )
}
