import { FETCH_TIMEOUT_MS } from 'data/queries'
import murmurhash from 'murmurhash'
import cache from 'state/cache'
import { baseUrl } from 'utils/actions'

export function getBaseUrl() {
    // If on server, use absolute path
    if (typeof window === 'undefined') {
        return baseUrl
    }
    // If on client, use relative path
    return ''
}

export function hashString(str) {
    return murmurhash.v3(str).toString()
}

export function withCache(handler) {
    return async function (req, res) {
        const bodyHash = hashString(JSON.stringify(req.body))
        const cachedResult = cache.get(bodyHash)
        if (cachedResult) {
            res.status(200).json(cachedResult)
            return
        }

        const result = await handler(req, res)
        cache.put(bodyHash, result, 1000 * 60 * 60 * 24) // cache for 24h

        return result
    }
}

export function withTimeout(handler) {
    return async function (req, res) {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => {
            controller.abort()
        }, FETCH_TIMEOUT_MS)

        try {
            return await handler(req, res, controller.signal)
        } catch (err) {
            console.error(`Fetch error: ${err.message}`)
            throw err
        } finally {
            clearTimeout(timeoutId)
        }
    }
}

export function withServerResponseHandling(fetchFunc) {
    return async function (req, res, ...args) {
        try {
            const response = await fetchFunc(req, res, ...args)
            const result = await response.json()
            if (response.ok) {
                res.status(200).json(result)
            } else {
                throw new Error(`Something went wrong: ${response.statusText}`)
            }
        } catch (err) {
            console.error(`Fetch error: ${err.message}`)
            res.status(500).json({ message: 'Error fetching data' })
        }
    }
}
