import { signal } from '@preact/signals-react'

const showTableControl = signal(false)
const isTableExpanded = signal(false)
const isTouchedExpansion = signal(false)

export const tableState = {
    showTableControl,
    isTableExpanded,
    isTouchedExpansion,
}
