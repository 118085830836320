import {
    ALL_KEY,
    CUSTODIAL_PROVIDER_KEY,
    DEFAULT_ORDER,
    DEFAULT_PROVIDER_CATEGORY,
    DEFAULT_PROVIDER_TIMEFRAME,
    HOSTING_KEY,
    LENDING_PROVIDER_KEY,
    LIQUID_STAKING_PROVIDER_KEY,
    ORDER_CHOICES,
    POS_PROVIDER_KEY,
    PROVIDER_ARCHIVE_CATEGORIES,
    SOLO_STAKING_KEY,
    TIMEFRAME_CHOICES,
} from 'utils/constants'
import {
    DEFAULT_PROVIDERS_SORT_BY,
    MAIN_PROVIDER_METRIC_GROUPS,
} from '../constants'
import { getObjectFromJsonString } from 'utils/actions'

export const getProviderMetricGroupsPerCategory = (
    categoryKey = DEFAULT_PROVIDER_CATEGORY.key
) => {
    if ([ALL_KEY, POS_PROVIDER_KEY].includes(categoryKey)) {
        return MAIN_PROVIDER_METRIC_GROUPS.filter(
            group => !['hosting_fee'].includes(group.key)
        )
    }

    if (
        [LIQUID_STAKING_PROVIDER_KEY, LENDING_PROVIDER_KEY].includes(
            categoryKey
        )
    ) {
        return MAIN_PROVIDER_METRIC_GROUPS.filter(
            group => !['hosting_fee', 'vsp'].includes(group.key)
        )
    }

    if ([SOLO_STAKING_KEY, HOSTING_KEY].includes(categoryKey)) {
        return MAIN_PROVIDER_METRIC_GROUPS.filter(group =>
            ['hosting_fee', 'vsp'].includes(group.key)
        )
    }

    if (categoryKey === CUSTODIAL_PROVIDER_KEY) {
        return []
    }

    return []
}

export const getProviderSortByOptionsPerCategory = (
    categoryKey = DEFAULT_PROVIDER_CATEGORY.key
) => {
    const metricGroups = getProviderMetricGroupsPerCategory(categoryKey)
    return metricGroups
        .filter(group => group.key !== 'hosting_fee')
        .map(group => ({
            key: group.key,
            name: group.label,
            withPercentage: group.withPercentage,
        }))
}

export const getDefaultProviderSortByOptionPerCategory = (
    categoryKey = DEFAULT_PROVIDER_CATEGORY.key
) => {
    const choices = getProviderSortByOptionsPerCategory(categoryKey)
    const sortByKeys = choices?.map(choice => choice.key) ?? []

    if (sortByKeys.includes(DEFAULT_PROVIDERS_SORT_BY.key)) {
        return DEFAULT_PROVIDERS_SORT_BY
    }

    return choices?.[0] ?? null
}

export const getParamsFromUrl = (query = {}, queryKey = '') => {
    const category =
        PROVIDER_ARCHIVE_CATEGORIES?.find(c => c?.key === query?.page) ??
        DEFAULT_PROVIDER_CATEGORY
    const sortBy =
        getProviderSortByOptionsPerCategory(category?.key)?.find(
            so => so?.key === query?.sort
        ) ?? getDefaultProviderSortByOptionPerCategory(category?.key)

    if (queryKey === 'asset') {
        return query?.asset ?? null
    }

    if (queryKey === 'sort') {
        return sortBy
    }

    if (queryKey === 'timeframe') {
        return sortBy
            ? TIMEFRAME_CHOICES?.find(ti => ti?.key === query?.timeframe) ??
                  DEFAULT_PROVIDER_TIMEFRAME
            : null
    }

    if (queryKey === 'order') {
        return sortBy
            ? ORDER_CHOICES?.find(or => or?.key === query?.order) ??
                  DEFAULT_ORDER
            : null
    }

    if (queryKey === 'byChange') {
        return String(query?.byChange) === 'true'
    }

    if (queryKey === 'verifiedFirst') {
        // true if not specified
        return query?.verifiedFirst !== undefined
            ? String(query?.verifiedFirst) === 'true'
            : true
    }

    if (queryKey === 'search') {
        return query?.search ?? ''
    }

    return category
}

export const getParamsFromQuery = query => {
    return {
        category: getParamsFromUrl(query, 'page'),
        asset: getParamsFromUrl(query, 'asset'),
        sortBy: getParamsFromUrl(query, 'sort'),
        timeframe: getParamsFromUrl(query, 'timeframe'),
        order: getParamsFromUrl(query, 'order'),
        search: getParamsFromUrl(query, 'search'),
        byChange: getParamsFromUrl(query, 'byChange'),
        verifiedFirst: getParamsFromUrl(query, 'verifiedFirst'),
    }
}

export const isProProvider = provider => {
    const vsp = provider?.metrics?.find(m => m?.metricKey === 'vsp')
    const verificationScore = getObjectFromJsonString(
        vsp?.variation
    )?.verificationScore
    const isPro = verificationScore?.ls?.pro || verificationScore?.pos?.pro
    return isPro
}
