import { Breadcrumbs } from './breadcrumbs'
import { HeaderTabs } from './tabs'
import { HeaderButtons } from './headerButtons'

export function Header() {
    return (
        <div className='px-[var(--p-page-sides)]'>
            <div className='container'>
                <div className='flex flex-col w-full border-b-2 border-solid border-black/10 dark:border-white/10'>
                    <div className='flex justify-between'>
                        <Breadcrumbs />
                        <div className='flex items-center gap-4'>
                            <div className='hidden md:flex'>
                                <HeaderTabs />
                            </div>
                            <HeaderButtons />
                        </div>
                    </div>
                    <div className='flex md:hidden'>
                        <HeaderTabs />
                    </div>
                </div>
            </div>
        </div>
    )
}
