import { useEffect, useRef } from 'react'

/**
 * Returns true is a component has mounted.
 * Use for components that rely on client-side logic or dimensions (e.g. window size) to
 * render client-side (after mounting) to prevent hydration errors caused by server-side
 * rendering differences.
 */
export const useIsMounted = () => {
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    return isMounted.current
}
