import { memo } from 'react'
import classNames from 'classnames'
import { Link } from 'components/ui/links/link'

import styles from './socialLinks.module.scss'

export const SocialLinksComponent = () => {
    return (
        <div className={styles.socialLinks}>
            {[
                {
                    icon: 'twitter',
                    href: 'https://twitter.com/stakingrewards',
                },
                {
                    icon: 'telegram',
                    href: 'https://t.me/stakingrewards',
                },
                {
                    icon: 'beehive',
                    href: 'https://newsletter.stakingrewards.com/',
                },
                {
                    icon: 'youtube',
                    className: styles.youtube,
                    href: 'https://www.youtube.com/c/StakingRewards',
                },
                {
                    icon: 'spotify',
                    href: 'https://open.spotify.com/show/6seC801qT42BwFpTntyoXo',
                },
                {
                    icon: 'discord',
                    href: 'https://discord.gg/EqDF9GF',
                },
                {
                    icon: 'instagram',
                    href: 'https://www.instagram.com/stakingrewards/',
                },
                {
                    icon: 'facebook',
                    href: 'https://www.facebook.com/stakingreawardsofficial/',
                },
                {
                    icon: 'linkedin',
                    href: 'https://www.linkedin.com/company/stakingrewards/',
                },
                {
                    icon: 'reddit',
                    href: 'https://www.reddit.com/r/staking/',
                },
            ].map((obj, idx) => (
                <Link key={`${obj.icon}-${idx}`} blank href={obj.href}>
                    <span
                        className={classNames(
                            `icon icon-${obj.icon}`,
                            styles.icon,
                            obj?.className
                        )}
                    />
                </Link>
            ))}
        </div>
    )
}

export const SocialLinks = memo(SocialLinksComponent)
