import { TrendingAssetsView } from './assetsView'

export function OverlaySearchView() {
    return (
        <div className='flex flex-col gap-8 px-[--p-page-sides] py-10'>
            <span className='text-5xl font-extrabold'>Search</span>
            <TrendingAssetsView />
        </div>
    )
}
