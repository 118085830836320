import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from './search.module.scss'
import { ToggleButtonComponent } from 'components/ui/buttons/toggleButton'

export const Search = ({
    searchTerms = '',
    setSearchTerms = () => {},
    doFocus = false,
    onEscape = () => {},
    large = false,
    className = '',
}) => {
    const inputRef = useRef(null)

    useEffect(() => {
        if (doFocus && inputRef?.current) {
            inputRef.current.focus()
        }
    }, [doFocus])

    return (
        <div
            className={classNames(styles.searchBox, className, {
                [styles.large]: large,
            })}
        >
            <span className={`icon icon-search ${styles.icon}`} />
            <input
                className={styles.input}
                ref={inputRef}
                type='text'
                placeholder='Search'
                value={searchTerms}
                onChange={e => setSearchTerms(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Escape') {
                        onEscape()
                    }
                }}
            />
            {searchTerms !== '' && (
                <div className={styles.clearBtn}>
                    <ToggleButtonComponent
                        expanded
                        hidden={searchTerms === ''}
                        onClick={() => {
                            setSearchTerms('')
                        }}
                    />
                </div>
            )}
        </div>
    )
}
