import dynamic from 'next/dynamic'
import { useRouterPages } from 'components/navbar/hooks'
import { MobileButtons } from './mobileButtons'

const WalletConnectDropdown = dynamic(
    () =>
        import('components/account/walletConnectDropdown').then(
            mod => mod.WalletConnectDropdown
        ),
    {
        ssr: false,
    }
)

const ChainSelector = dynamic(
    () =>
        import('components/header/chainSelector').then(
            mod => mod.ChainSelector
        ),
    {
        ssr: false,
    }
)

export function HeaderButtons() {
    const {
        isStakeAppPage,
        isSwapAppPage,
        isPortfolioDashboard,
        isPortfolioLandingPage,
    } = useRouterPages()

    return (
        <div className='flex gap-8 items-center'>
            <MobileButtons />
            {isPortfolioDashboard ? <ChainSelector /> : null}
            {isStakeAppPage ||
            isSwapAppPage ||
            isPortfolioDashboard ||
            isPortfolioLandingPage ? (
                <WalletConnectDropdown />
            ) : null}
        </div>
    )
}
