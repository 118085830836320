export const assetSections = [
    {
        id: 'about',
        slug: 'about',
        title: 'About',
    },
    {
        id: 'providers',
        slug: '',
        title: 'Providers',
    },
    {
        id: 'analytics',
        slug: 'analytics',
        title: 'Analytics',
    },
    {
        id: 'calculator',
        slug: 'calculator',
        title: 'Calculator',
    },
]

export const assetSectionsIds = assetSections.reduce(
    (acc, { id }) => ({ ...acc, [id]: id }),
    {}
)
