import { useState } from 'react'
import classNames from 'classnames'
import { truncateString } from 'utils/formatter'

export const TextWithReadMore = ({
    text = '',
    className = '',
    maxCharactersCount = 150,
}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const withReadMore = String(text).length > maxCharactersCount

    const textToShow =
        withReadMore && !isExpanded
            ? truncateString(text, maxCharactersCount)
            : text

    return (
        <div className={classNames('flex flex-col gap-y-3', className)}>
            <p className={classNames('text-xs font-normal text-contrast-4')}>
                {textToShow}
            </p>
            {withReadMore && !isExpanded && (
                <span
                    className='text-xs font-normal text-primary cursor-pointer hover:opacity-80'
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsExpanded(true)
                    }}
                >
                    Read more
                </span>
            )}
        </div>
    )
}
