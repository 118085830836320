import { useTheme } from 'state'

import styles from './box.module.scss'

export function Box({ className = '', fgClass = '', children }) {
    const theme = useTheme()

    return (
        <div className={[styles.box, className].join(' ')} data-theme={theme}>
            <div className={styles.bg} />
            <div className={[styles.fg, fgClass].join(' ')}>{children}</div>
        </div>
    )
}
