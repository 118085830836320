import styles from './slider.module.scss'

export const Slider = ({
    minValue = 0,
    maxValue = 0,
    value = 0,
    onChange = () => {},
    step = 1,
    inputClassName = '',
}) => {
    return (
        <div className={styles.slider}>
            <input
                className={inputClassName}
                tabIndex={1}
                type='range'
                min={minValue}
                max={maxValue}
                value={value}
                onChange={onChange}
                step={step}
            />
        </div>
    )
}
