import {
    CUSTODIAL_PROVIDER_KEY,
    DEFAULT_ORDER,
    DEFAULT_REWARD_OPTION_CATEGORY,
    DEFAULT_REWARD_OPTION_TIMEFRAME,
    HOSTING_KEY,
    LENDING_PROVIDER_KEY,
    LIQUID_STAKING_PROVIDER_KEY,
    ORDER_CHOICES,
    POS_PROVIDER_KEY,
    REWARD_OPTION_CATEGORIES,
    SMART_CONTRACT_KEY,
    SOLO_STAKING_KEY,
    TIMEFRAME_CHOICES,
    TYPE_ASSET,
    TYPE_PROVIDER,
    CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
    ALL_KEY,
    OPERATOR_KEY,
    AVS_KEY,
    RewardOptionType,
    BABYLON_STAKING_KEY,
} from 'utils/constants'
import {
    DEFAULT_REWARD_OPTIONS_FOR_PROVIDER_SORT_BY,
    DEFAULT_REWARD_OPTIONS_SORT_BY,
    REWARD_OPTIONS_METRIC_GROUPS,
    REWARD_OPTIONS_METRIC_GROUPS_AVS,
    STAKED_TOKENS_METRIC_KEY,
} from '../constants'
import {
    NO_DATA_INDICATOR,
    capitalize,
    ellipseString,
    toCamelCase,
} from 'utils/formatter'
import { StatusLabel } from 'components/ui/itemWithLogo'
import { getObjectFromJsonString } from 'utils/actions'

export const getRewardOptionMetricGroupsPerCategory = (
    categoryKey = DEFAULT_REWARD_OPTION_CATEGORY.key,
    slug = '',
    isOperator = false,
    withPerformanceMetric = false,
    type
) => {
    let rewardOptionMetricGroups = REWARD_OPTIONS_METRIC_GROUPS

    // Filter out 'performance' if not applicable
    if (
        !withPerformanceMetric ||
        ![POS_PROVIDER_KEY, ALL_KEY, OPERATOR_KEY].includes(categoryKey)
    ) {
        rewardOptionMetricGroups = rewardOptionMetricGroups.filter(
            gr => gr.key !== 'performance'
        )
    }

    if (type === TYPE_PROVIDER && categoryKey === ALL_KEY) {
        return rewardOptionMetricGroups.filter(
            group =>
                group.key !== CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY &&
                group.key !== STAKED_TOKENS_METRIC_KEY &&
                group.key !== 'commission' &&
                group.key !== 'staking_share' &&
                group.key !== 'hosting_fee'
        )
    }

    if (categoryKey === OPERATOR_KEY) {
        return rewardOptionMetricGroups.filter(group =>
            [
                'staking_wallets',
                'reward_rate',
                'assets_under_management',
                'commission',
            ].includes(group.key)
        )
    }

    if (type === TYPE_PROVIDER && categoryKey === BABYLON_STAKING_KEY) {
        return rewardOptionMetricGroups.filter(group =>
            ['staked_tokens'].includes(group.key)
        )
    }

    if (type === TYPE_PROVIDER && categoryKey === SMART_CONTRACT_KEY) {
        return rewardOptionMetricGroups.filter(
            group =>
                !['assets_under_management', 'hosting_fee'].includes(group.key)
        )
    }

    if (isOperator) {
        const metricGroupsCopy = [...rewardOptionMetricGroups]
        const rewardRateIndex = metricGroupsCopy.findIndex(
            metricGroup => metricGroup.key === 'reward_rate'
        )

        if (rewardRateIndex !== -1) {
            const modifiedObject = {
                ...metricGroupsCopy[rewardRateIndex],
                label: 'Operator Rate',
            }
            metricGroupsCopy[rewardRateIndex] = modifiedObject
        }
        rewardOptionMetricGroups = metricGroupsCopy.filter(
            group => !['staking_share'].includes(group.key)
        )
    }

    if (slug === 'ethereum-2-0') {
        rewardOptionMetricGroups = rewardOptionMetricGroups.map(group => {
            if (group.key === 'staking_wallets') {
                return {
                    ...group,
                    label: 'Validators',
                    tooltipTextObj: {
                        title: 'Validators',
                        text: `Validators are nodes managed by this operator, each requiring a 32 ETH deposit.
                    They store data, process transactions, and create new blocks, contributing to Ethereum's consensus via Proof of Stake.
                    This metric indicates the operator's engagement in upholding network security and protocol reliability.
                    More validators signify a stronger role in network validation, potentially enhancing Ethereum's performance.`,
                    },
                }
            }
            return group
        })
    } else if (slug === 'elastos') {
        rewardOptionMetricGroups = rewardOptionMetricGroups.filter(
            group => !['staking_wallets'].includes(group.key)
        )
    }

    // Filtering based on categoryKey

    if (categoryKey === POS_PROVIDER_KEY) {
        return rewardOptionMetricGroups.filter(
            group => !['hosting_fee'].includes(group.key)
        )
    }

    if (categoryKey === LIQUID_STAKING_PROVIDER_KEY) {
        return rewardOptionMetricGroups.filter(
            group => !['self_staked_tokens', 'hosting_fee'].includes(group.key)
        )
    }

    if (categoryKey === LENDING_PROVIDER_KEY) {
        return rewardOptionMetricGroups.filter(
            group =>
                ![
                    'commission',
                    'staking_share',
                    'self_staked_tokens',
                    'hosting_fee',
                ].includes(group.key)
        )
    }

    if (categoryKey === CUSTODIAL_PROVIDER_KEY) {
        return rewardOptionMetricGroups.filter(group =>
            ['reward_rate', 'commission'].includes(group.key)
        )
    }

    if (categoryKey === HOSTING_KEY || categoryKey === SOLO_STAKING_KEY) {
        return rewardOptionMetricGroups.filter(group =>
            ['reward_rate', 'hosting_fee'].includes(group.key)
        )
    }

    if (categoryKey === SMART_CONTRACT_KEY) {
        return rewardOptionMetricGroups.filter(
            group => !['commission', 'self_staked_tokens'].includes(group.key)
        )
    }

    if (categoryKey === AVS_KEY) {
        return [
            ...REWARD_OPTIONS_METRIC_GROUPS_AVS,
            {
                ...rewardOptionMetricGroups.find(
                    mg => mg.key === 'staked_tokens'
                ),
                label: 'Restaked ETH',
                withoutAuM: true,
                width: undefined,
            },
            {
                ...rewardOptionMetricGroups.find(
                    mg => mg.key === 'staking_wallets'
                ),
                label: 'Restakers',
                width: undefined,
            },
        ]
    }

    return rewardOptionMetricGroups
}

export const getRewardOptionSortByOptionsPerCategory = (
    categoryKey = DEFAULT_REWARD_OPTION_CATEGORY.key,
    type = TYPE_ASSET,
    slug = '',
    withPerformanceMetric = false
) => {
    let metricGroups = getRewardOptionMetricGroupsPerCategory(
        categoryKey,
        slug,
        false,
        withPerformanceMetric,
        type
    )
    return metricGroups.map(group => ({
        key: group.key,
        name: group.label,
        withPercentage: group.withPercentage,
    }))
}

export const getDefaultRewardOptionSortByOptionPerCategory = (
    categoryKey = DEFAULT_REWARD_OPTION_CATEGORY.key,
    type = TYPE_ASSET,
    slug = ''
) => {
    const choices = getRewardOptionSortByOptionsPerCategory(
        categoryKey,
        type,
        slug,
        false
    )
    const sortByKeys = choices?.map(choice => choice.key) ?? []

    if (type === TYPE_PROVIDER) {
        if (
            sortByKeys.includes(DEFAULT_REWARD_OPTIONS_FOR_PROVIDER_SORT_BY.key)
        ) {
            return DEFAULT_REWARD_OPTIONS_FOR_PROVIDER_SORT_BY
        }
    } else {
        if (sortByKeys.includes(DEFAULT_REWARD_OPTIONS_SORT_BY?.key)) {
            return slug === 'polkadot'
                ? {
                      key: 'performance',
                      name: 'Performance',
                      withPercentage: false,
                  }
                : DEFAULT_REWARD_OPTIONS_SORT_BY
        }
    }

    return choices?.[0]
}

export const getParamsFromUrl = (
    query = {},
    queryKey = '',
    type = TYPE_ASSET
) => {
    const defaultROCategoryAll = REWARD_OPTION_CATEGORIES.find(
        cat => cat.key === ALL_KEY
    )
    if (queryKey === 'category') {
        return (
            REWARD_OPTION_CATEGORIES?.find(c => c?.key === query?.category) ??
            (query?.slug === 'ethereum-2-0'
                ? REWARD_OPTION_CATEGORIES.find(
                      cat => cat.key === LIQUID_STAKING_PROVIDER_KEY
                  )
                : defaultROCategoryAll)
        )
    }

    if (queryKey === 'sort') {
        return (
            getRewardOptionSortByOptionsPerCategory(
                query?.category ?? defaultROCategoryAll,
                type,
                query?.slug,
                true
            )?.find(so => so?.key === query?.sort) ??
            getDefaultRewardOptionSortByOptionPerCategory(
                defaultROCategoryAll,
                type,
                query?.slug
            )
        )
    }

    if (queryKey === 'timeframe') {
        return (
            TIMEFRAME_CHOICES?.find(ti => ti?.key === query?.timeframe) ??
            DEFAULT_REWARD_OPTION_TIMEFRAME
        )
    }

    if (queryKey === 'order') {
        return (
            ORDER_CHOICES?.find(or => or?.key === query?.order) ?? DEFAULT_ORDER
        )
    }

    if (queryKey === 'byChange') {
        return String(query?.byChange) === 'true'
    }

    if (queryKey === 'search') {
        return query?.search ?? ''
    }

    if (queryKey === 'verifiedFirst') {
        // true if not specified
        return query?.verifiedFirst !== undefined
            ? String(query?.verifiedFirst) === 'true'
            : query?.slug === 'polkadot'
              ? false
              : true
    }

    return defaultROCategoryAll
}

export const getRewardOptionCategoryLabelByKey = (categoryKey = ALL_KEY) => {
    // Assign shorter names
    if (categoryKey === HOSTING_KEY) {
        return 'VaaS'
    }

    if (categoryKey === SMART_CONTRACT_KEY) {
        return 'Smart Contract'
    }

    return (
        REWARD_OPTION_CATEGORIES.find(cat => cat.key === categoryKey)?.name ??
        ''
    )
}

export const getSupportedRewardOptionCategoryKeys = (
    profileType = TYPE_ASSET,
    slug = ''
) => {
    if (profileType === TYPE_ASSET) {
        return REWARD_OPTION_CATEGORIES.map(category => category.key).filter(
            c => {
                return (
                    slug !== 'ethereum-2-0' ||
                    ![POS_PROVIDER_KEY, SMART_CONTRACT_KEY].includes(c)
                )
            }
        )
    }

    return REWARD_OPTION_CATEGORIES.filter(
        category => category.key !== AVS_KEY
    ).map(category => category.key)
}

export const getStatusAndName = (headerItem = null, item = null) => {
    let fullName = ''
    let status = ''
    if (item?.type?.key === POS_PROVIDER_KEY) {
        if (headerItem) {
            fullName = headerItem?.name ?? NO_DATA_INDICATOR
            status = item?.validators?.find(v => v?.status?.label === 'active')
                ? StatusLabel.Active
                : StatusLabel.Standby
        } else {
            const validator = item?.validators?.[0]
            fullName = validator?.address ?? NO_DATA_INDICATOR
            status = capitalize(
                validator?.status?.label ?? StatusLabel.Inactive
            )
        }
    } else {
        fullName = headerItem?.name ?? NO_DATA_INDICATOR
        // Show status only for PoS ROs
        status = StatusLabel.None
    }
    const name =
        String(fullName).length > 20 ? ellipseString(fullName) : fullName

    return { name, status }
}

export const getPrimaryRewardOptionMetricGroups = (
    slug = '',
    categoryKey = '',
    withPerformanceMetric = false
) => {
    const allMetricKeys = getRewardOptionMetricGroupsPerCategory(
        categoryKey,
        slug,
        false,
        withPerformanceMetric
    ).map(m => m.key)

    if (
        (categoryKey === POS_PROVIDER_KEY || categoryKey === ALL_KEY) &&
        withPerformanceMetric
    ) {
        return allMetricKeys.filter(m =>
            [
                'reward_rate',
                'commission',
                'performance',
                'assets_under_management',
            ].includes(m)
        )
    }

    if (categoryKey === HOSTING_KEY) {
        return allMetricKeys.filter(m =>
            [
                'reward_rate',
                'assets_under_management',
                'staking_wallets',
                CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
            ].includes(m)
        )
    }

    if (categoryKey === SOLO_STAKING_KEY) {
        return allMetricKeys.filter(m =>
            ['reward_rate', 'assets_under_management', 'hosting_fee'].includes(
                m
            )
        )
    }

    if (categoryKey === OPERATOR_KEY) {
        return allMetricKeys.filter(m =>
            [
                'reward_rate',
                'commission',
                'staking_wallets',
                'performance',
                'assets_under_management',
            ].includes(m)
        )
    }

    return allMetricKeys.filter(m =>
        [
            'reward_rate',
            'commission',
            'assets_under_management',
            'staking_wallets',
        ].includes(m)
    )
}

export const getRewardOptionHeaderDetails = (
    rewardOption = null,
    profileItem = null,
    isProviderProfile = null,
    operator = null
) => {
    const rewardOptionTypeKey = rewardOption?.type?.key
    const rewardOptionCategoryLabel =
        getRewardOptionCategoryLabelByKey(rewardOptionTypeKey)

    if (
        !isProviderProfile ||
        ![POS_PROVIDER_KEY, LIQUID_STAKING_PROVIDER_KEY].includes(
            profileItem?.type?.key
        ) ||
        rewardOptionTypeKey !== OPERATOR_KEY ||
        !operator
    ) {
        return rewardOptionCategoryLabel
    }

    // For PoS and Liquid staking provider profiles, operator ROs with operator provider available:
    const operatorName = operator?.name
        ? operator.name.length > 20
            ? ellipseString(operator.name)
            : operator.name
        : NO_DATA_INDICATOR

    return `${
        profileItem?.type?.key === LIQUID_STAKING_PROVIDER_KEY
            ? 'Operated by'
            : `${rewardOptionCategoryLabel} for`
    } ${operatorName}`
}

// For LST:
export const checkIsStakeableLst = (
    outputAsset = null,
    inputAsset = null,
    rewardOption = null
) => {
    const metaConfig = getObjectFromJsonString(outputAsset?.metaWebApp)
    return (
        !!metaConfig?.contract &&
        !!inputAsset?.slug &&
        !!rewardOption?.stakeableInApp
    )
}

// For PoS:
export const checkIsDelegatable = (
    inputAsset = null,
    provider = null,
    rewardOption = null
) => {
    return (
        !!inputAsset?.slug && !!provider?.slug && rewardOption?.stakeableInApp
    )
}

export const getTotalCountPerCategoryKey = (
    categoryKey = '',
    countsData = null
) => {
    let totalCount = 0
    if (categoryKey === RewardOptionType.RUN_VALIDATOR) {
        const hostingCount =
            countsData?.[toCamelCase(HOSTING_KEY)]?.rewardOptions?.count ?? 0
        const soloStakingCount =
            countsData?.[toCamelCase(SOLO_STAKING_KEY)]?.rewardOptions?.count ??
            0
        totalCount = hostingCount + soloStakingCount
    } else if (categoryKey === RewardOptionType.SMART_CONTRACT) {
        const soloStakingCount =
            countsData?.[toCamelCase(SMART_CONTRACT_KEY)]?.rewardOptions
                ?.count ?? 0
        const lendingCount =
            countsData?.[toCamelCase(LENDING_PROVIDER_KEY)]?.rewardOptions
                ?.count ?? 0
        totalCount = soloStakingCount + lendingCount
    } else if (categoryKey === RewardOptionType.CUSTODIAL) {
        totalCount =
            countsData?.[toCamelCase(CUSTODIAL_PROVIDER_KEY)]?.providers
                ?.count ?? 0
    } else {
        totalCount =
            countsData?.[toCamelCase(categoryKey)]?.rewardOptions?.count ?? 0
    }
    return totalCount
}
