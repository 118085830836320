import numeral from 'numeral'

export const MAX_TOOLTIP_TEXT_LENGTH = 50
export const DECIMALS_LIMIT_DEFAULT = 2
export const MAX_SIGNIFICANT_FIGURES_NUMBER = 6
export const DECIMALS_LIMIT_USD = 2
export const DECIMALS_LIMIT_TOKEN = 6
export const MINIMUM_NUMBER = 0.000001
export const NO_DATA_INDICATOR = '-'
export const DECIMAL_SEPARATOR = '.'
export const EMPTY_STAKING_TIME_OBJECT = {
    years: '',
    months: '',
    days: '',
}
const MAX_UNIT_NUMBER_IN_STRING = 2

export const isInvalidNumber = (number = undefined) => {
    return (
        isNaN(number) ||
        number === '' ||
        number === null ||
        number === undefined ||
        number === NO_DATA_INDICATOR ||
        !Number.isFinite(Number(number))
    )
}

export const getPrecisionByDigitsNumber = (number = '') => {
    const maxDigitsNumber =
        Math.abs(Number(number)) < 1
            ? Math.abs(Math.log10(MINIMUM_NUMBER)) + 1
            : MAX_SIGNIFICANT_FIGURES_NUMBER
    const integerPart = number.toString().split('.')?.[0] ?? ''
    return Math.max(0, maxDigitsNumber - integerPart.length)
}

export const countDecimalPlaces = (number = '') => {
    if (isInvalidNumber(number) || Number.isInteger(number)) {
        return 0
    }
    const match = number.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
    if (!match) {
        return 0
    }
    const fractionalPart = match[1] || ''
    return fractionalPart?.length ?? 0
}

export const getPrecisionBySignificantFigures = (value = 0, maxDigits = 3) => {
    if (!value) return 0

    const absValue = Math.abs(value)

    if (absValue >= 1) {
        const intPartLength = String(Math.floor(absValue)).length
        return Math.max(0, maxDigits - intPartLength)
    }

    const magnitude = Math.floor(Math.log10(absValue))
    return maxDigits - magnitude - 1
}

// Get a rounded number with the specified number of decimal places
export const formatInputNumber = (
    number = null,
    precision = DECIMALS_LIMIT_DEFAULT
) => {
    if (isInvalidNumber(number)) return NaN

    const num = Number(number)
    return Number(num?.toFixed(precision))
}

const DEFAULT_FORMATTING_PARAMS = {
    precision: null,
    allowEmpty: true, // If true, will return an empty string for an empty number, otherwise NO_DATA_INDICATOR
    withAbbreviation: true, // if true, it will use k - thousand, m - million, b - billion, t - trillion
    forcePrecision: true, // if true, it will keep the specified number of decimal places, otherwise it will truncate the trailing zeros.
    showApproximation: false, // if true, it will show ~0, otherwise it will show the actual within the precision
    prefix: '',
    postfix: '',
    spaceBeforeNumber: false, // if true, it will add a space between the number and the postfix
    spaceAfterNumber: false, // if true, it will add a space between the number and the postfix
    showPlus: false,
}

export const formatOutputNumber = (number = '', customParams = {}) => {
    const params = { ...DEFAULT_FORMATTING_PARAMS, ...customParams }
    if (params.allowEmpty && number === '') {
        return ''
    }

    if (isInvalidNumber(number)) return NO_DATA_INDICATOR

    const numericValue = isInvalidNumber(numeral(number).value())
        ? parseFloat(number)
        : numeral(number).value()

    const precision =
        params.precision === null
            ? getPrecisionByDigitsNumber(numericValue)
            : params.precision

    let formattedNumber
    const absNumber = Math.abs(numericValue)
    const isSmallNumber =
        Number(absNumber.toFixed(precision)) < MINIMUM_NUMBER && absNumber !== 0

    const hasPrefix = params.prefix !== ''
    let signStr = ''
    if (numericValue !== 0) {
        signStr = numericValue < 0 ? '-' : params.showPlus ? '+' : ''
    }
    const prefixSpaced =
        hasPrefix && params.spaceBeforeNumber
            ? `${params.prefix} ${signStr}`
            : `${signStr}${params.prefix}`
    const hasPostfix = params.postfix !== ''
    const postfixSpaced =
        hasPostfix && params.spaceAfterNumber
            ? ` ${params.postfix}`
            : params.postfix

    if (params.showApproximation && isSmallNumber) {
        formattedNumber = `${prefixSpaced}0${postfixSpaced}`
    } else {
        let format
        if (precision === 0) {
            if (params.withAbbreviation) {
                format = `0,0a`
            } else {
                format = `0,0`
            }
        } else {
            if (params.withAbbreviation) {
                if (params.forcePrecision) {
                    format = `0,0.${'0'.repeat(precision)}a`
                } else {
                    format = `0,0[.][${'0'.repeat(precision)}]a`
                }
            } else {
                if (params.forcePrecision) {
                    format = `0,0.${'0'.repeat(precision)}`
                } else {
                    format = `0,0[.][${'0'.repeat(precision)}]`
                }
            }
        }

        formattedNumber = `${prefixSpaced}${numeral(absNumber).format(
            format
        )}${postfixSpaced}`
    }

    return formattedNumber
}

export const formatPercentage = (percentage = null) => {
    return formatOutputNumber(percentage, {
        precision: DECIMALS_LIMIT_DEFAULT,
        postfix: '%',
        spaceAfterNumber: false,
        allowEmpty: false,
        withAbbreviation: false,
        forcePrecision: false,
        showApproximation: true,
    })
}

export const formatSeconds = (
    seconds = null,
    full = true,
    maxUnits = MAX_UNIT_NUMBER_IN_STRING
) => {
    if (isInvalidNumber(seconds)) return NO_DATA_INDICATOR

    if (seconds === 0) return `0${full ? ' seconds' : 's'}`

    const years = Math.floor(seconds / (60 * 60 * 24 * 365)) // NB: 365 days in a year
    const months = Math.floor(
        (seconds % (60 * 60 * 24 * 365)) / (60 * 60 * 24 * (365 / 12)) //NB: 365 / 12 days in a month
    )
    const days = Math.floor(
        (seconds % (60 * 60 * 24 * (365 / 12))) / (60 * 60 * 24)
    )
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
    const minutes = Math.floor((seconds % (60 * 60)) / 60)
    const remainingSeconds = seconds % 60

    const yearPostfix = full ? (years === 1 ? ' year' : ' years') : 'y'
    const monthPostfix = full ? (months === 1 ? ' month' : ' months') : 'mo'
    const dayPostfix = full ? (days === 1 && full ? ' day' : ' days') : 'd'
    const hourPostfix = full ? (hours === 1 && full ? ' hour' : ' hours') : 'h'
    const minutePostfix = full
        ? minutes === 1 && full
            ? ' minute'
            : ' minutes'
        : 'm'
    const secondPostfix = full
        ? remainingSeconds === 1 && full
            ? ' second'
            : ' seconds'
        : 's'

    const yearsPart = years !== 0 ? `${years}${yearPostfix}` : ''
    const monthsPart = months !== 0 ? `${months}${monthPostfix}` : ''
    const daysPart = days !== 0 ? `${days}${dayPostfix}` : ''
    const hoursPart = hours !== 0 ? `${hours}${hourPostfix}` : ''
    const minutesPart = minutes !== 0 ? `${minutes}${minutePostfix}` : ''
    const secondsPart =
        remainingSeconds !== 0
            ? `${formatInputNumber(remainingSeconds, 0)}${secondPostfix}`
            : ''

    const significantParts = [
        yearsPart,
        monthsPart,
        daysPart,
        hoursPart,
        minutesPart,
        secondsPart,
    ].filter(part => part !== '')
    const partsToShow =
        significantParts.length > maxUnits
            ? significantParts.slice(0, maxUnits)
            : significantParts

    return partsToShow.join(' ')
}

export const formatTime = (
    timeValue = null,
    units = '',
    precision = DECIMALS_LIMIT_DEFAULT,
    fullUnit = true
) => {
    if (isInvalidNumber(timeValue)) return NO_DATA_INDICATOR

    const unitString =
        String(timeValue) === String(1) ? units?.slice(0, -1) : units

    const formattedNumber = formatOutputNumber(timeValue, {
        precision,
        allowEmpty: false,
        withAbbreviation: false,
        forcePrecision: false,
        showApproximation: false,
    })

    // Units are in a plural form
    return `${formattedNumber}${
        fullUnit ? ` ${unitString}` : units?.slice(0, 1)
    }`
}

export const capitalize = (str = '') =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const capitalizeAll = (str = '') =>
    str
        .split(' ')
        .map(word => capitalize(word))
        .join(' ')

export const timeObjectToString = (
    timeDurationObject = EMPTY_STAKING_TIME_OBJECT,
    fullUnit = true,
    doCapitalize = true
) => {
    const years = formatTime(
        timeDurationObject.years,
        !doCapitalize ? 'years' : capitalize('years'),
        0,
        fullUnit
    )
    const months = formatTime(
        timeDurationObject.months,
        !doCapitalize ? 'months' : capitalize('months'),
        0,
        fullUnit
    )
    const days = formatTime(
        timeDurationObject.days,
        !doCapitalize ? 'days' : capitalize('days'),
        0,
        fullUnit
    )

    const yearsString =
        years === NO_DATA_INDICATOR || timeDurationObject.years === 0
            ? ''
            : years
    const monthsString =
        months === NO_DATA_INDICATOR || timeDurationObject.months === 0
            ? ''
            : months
    const daysString =
        days === NO_DATA_INDICATOR || timeDurationObject.days === 0 ? '' : days

    return [yearsString, monthsString, daysString]
        .filter(str => str !== '')
        .join(' ')
}

export const toCamelCase = (str = '') =>
    str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())

export const truncateString = (str = '', afterCharactersCount = 50) => {
    if (!str || str.length <= afterCharactersCount) {
        return str
    }
    let lastSpaceIndex = -1
    for (let i = afterCharactersCount; i < str.length; i++) {
        if (str[i] === ' ') {
            if (['.', ','].includes(str?.[i - 1])) {
                lastSpaceIndex = i - 1
            } else {
                lastSpaceIndex = i
            }
            break
        }
    }

    return `${str.slice(
        0,
        lastSpaceIndex !== -1 ? lastSpaceIndex : afterCharactersCount
    )}...`
}

export const ellipseString = (str = '') => {
    const string = String(str)
    if (string.length <= 10) {
        return string
    } else {
        return string.slice(0, 4) + '...' + string.slice(-3)
    }
}

export const formatDaysToObject = (days = 0) => {
    return {
        years: Math.floor(days / 365),
        months: Math.floor((days % 365) / 30),
        days: Math.floor((days % 365) % 30),
    }
}

export const formatDateToISO = (date = new Date()) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const formatUsd = amount => {
    return formatOutputNumber(amount, {
        precision: DECIMALS_LIMIT_USD,
        forcePrecision: true,
        prefix: '$',
    })
}

export const formatToken = (amount, symbol) => {
    return formatOutputNumber(amount, {
        precision: DECIMALS_LIMIT_TOKEN,
        forcePrecision: false,
        postfix: symbol || '',
        spaceAfterNumber: true,
    })
}
