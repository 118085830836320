import { Delimiter } from './delimiter'
import { NavBarItem } from './navbarItem'

export function MobileNavBar() {
    return (
        <div className='flex flex-col justify-between py-6'>
            <div className='flex flex-col items-start'>
                <NavBarItem
                    title='Discover'
                    iconClassName='icon-discover'
                    link='/assets'
                    activeLinks={['/asset', '/providers', '/provider']}
                />
                <NavBarItem
                    title='Swap'
                    iconClassName='icon-swap'
                    link='/swap'
                />
                <NavBarItem
                    title='Stake'
                    iconClassName='icon-coins'
                    link='/stake-app'
                />
                <NavBarItem
                    title='Optimize'
                    iconClassName='icon-chart-pie'
                    link='/terminal'
                />
                <Delimiter />
            </div>
            <div className='flex flex-col items-start'>
                <NavBarItem
                    title='API'
                    iconClassName='icon-api-letter'
                    link='/staking-data-api'
                />
                <NavBarItem
                    title='Summit'
                    iconClassName='icon-summit'
                    link='/summit'
                />
            </div>
        </div>
    )
}
