import {
    createContext,
    useState,
    useCallback,
    useContext,
    useEffect,
} from 'react'
import { storageState } from 'state/storage'
import { useMediaPredicate } from 'react-media-hook'
import { usePageSections } from 'hooks/usePageSections'

const NavigationContext = createContext({
    isDesktopNavbarOpen: true,
    breadcrumbTitle: null,
    overlayScreen: null,
    setIsDesktopNavbarOpen: () => {},
    setBreadcrumbTitle: () => {},
    setOverlayScreen: () => {},
})

export function NavigationContextProvider({ children }) {
    const isDesktopNavbarOpenFromStorage =
        storageState.isDesktopNavbarOpen.value

    const [breadcrumbTitle, setBreadcrumbTitle] = useState(null)
    const [overlayScreen, setOverlayScreen] = useState(null)

    const setIsDesktopNavbarOpen = useCallback(value => {
        storageState.isDesktopNavbarOpen.value = value
    }, [])

    // if isDesktopNavbarOpenFromStorage is null,
    // then we need to set isDesktopNavbarOpen to true if the screen width is greater than tablet
    const isTablet = useMediaPredicate('(min-width: 768px)')
    const isDesktopNavbarOpen = isDesktopNavbarOpenFromStorage ?? isTablet

    const { selectedSectionId, setSelectedSectionId, pageSections } =
        usePageSections()

    return (
        <NavigationContext.Provider
            value={{
                isDesktopNavbarOpen,
                setIsDesktopNavbarOpen,

                breadcrumbTitle,
                setBreadcrumbTitle,

                overlayScreen,
                setOverlayScreen,

                selectedSectionId,
                setSelectedSectionId,
                pageSections,
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export function useNavigationContext() {
    return useContext(NavigationContext)
}

export function useSetBreadcrumbTitle(title) {
    const { setBreadcrumbTitle } = useNavigationContext()

    useEffect(() => {
        if (title) {
            setBreadcrumbTitle(title)
        }

        return () => {
            setBreadcrumbTitle(null)
        }
    }, [title, setBreadcrumbTitle])
}
