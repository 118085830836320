import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'
import { useMediaPredicate } from 'react-media-hook'
import { fetchSelectedProviderProfile } from 'data/queries'
import { useNavigationContext } from 'contexts/navigationContext'
import { Tab } from './tab'
import { TabsContainer } from './tabsContainer'

const getBlacklistedSections = (provider = null) => {
    const blacklistedSections = new Set()

    if (provider?.isVerified === false) {
        blacklistedSections.add('enterprise')
    }

    return Array.from(blacklistedSections)
}

export function ProviderTabs() {
    const { selectedSectionId, setSelectedSectionId, pageSections } =
        useNavigationContext()
    const params = useParams()

    const { slug } = params || {}

    const { data: provider } = useQuery({
        queryKey: ['providerProfile', slug],
        queryFn: async () => {
            const data = await fetchSelectedProviderProfile(slug)
            return data.providers[0]
        },
        enabled: Boolean(slug),
    })

    const handleClick = useCallback(
        id => {
            setSelectedSectionId(id)
        },
        [setSelectedSectionId]
    )

    const blacklistedSections = getBlacklistedSections(provider)

    const filteredPageSections = pageSections.filter(
        ({ id }) => !blacklistedSections.includes(id)
    )

    const isTablet = useMediaPredicate('(min-width: 768px)')

    return (
        <TabsContainer>
            {filteredPageSections.map(({ id, title }) => (
                <Tab
                    key={id}
                    id={id}
                    title={
                        id === 'supportedAssets' && !isTablet ? 'Assets' : title
                    }
                    onClick={handleClick}
                    isActive={selectedSectionId === id}
                />
            ))}
        </TabsContainer>
    )
}
