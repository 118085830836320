import { Link } from 'components/ui'

export function FooterLink({ title, link, blank = false }) {
    return (
        <Link
            href={link}
            blank={blank}
            className='text-contrast-3 hover:text-contrast-4 hover:underline'
        >
            {title}
        </Link>
    )
}
