import { NavBarItem } from './navbarItem'
import { useNavigationContext } from 'contexts/navigationContext'

export function StateNavbarItem() {
    const { isDesktopNavbarOpen, setIsDesktopNavbarOpen } =
        useNavigationContext()

    return (
        <NavBarItem
            iconClassName='icon-navbar'
            onClick={() => setIsDesktopNavbarOpen(!isDesktopNavbarOpen)}
        />
    )
}
