import Image from 'next/image'

export const Flag = ({ country = 'us', width = 30, height = 24 }) => {
    const countryCode = country ? String(country).toUpperCase() : 'EARTH'
    return (
        <Image
            src={`/static/flags/${countryCode}.svg`}
            alt={`${countryCode} Flag`}
            height={height}
            width={width}
            style={{
                height: `${height}px`,
                width: `${width}px`,
            }}
        />
    )
}
