import { FooterLink } from './footerLink'
import { PRODUCT_LINKS, ENTERPRISE_LINKS, LEGAL_LINKS } from './constants'
import { Link } from 'components/ui'
import { Tooltip } from 'react-tooltip'
import { OptionButton } from 'components/ui/buttons/optionButton'

export function Footer() {
    return (
        <div className='px-[var(--p-page-sides)]'>
            <div className='container'>
                <div className='flex flex-col w-full py-6 border-t-2 border-solid border-black/10 dark:border-white/10'>
                    <div className='flex flex-wrap justify-between gap-4'>
                        <div className='flex flex-col items-start gap-4 md:flex-row md:items-center'>
                            <Link href='/'>
                                <div className='flex items-center w-10 h-5'>
                                    <span className='icon icon-sr [--size:40px] [--color:black] dark:[--color:--c-lightest]' />
                                </div>
                            </Link>
                            {PRODUCT_LINKS.map(({ title, link, blank }) => (
                                <FooterLink
                                    key={link}
                                    title={title}
                                    link={link}
                                    blank={blank}
                                />
                            ))}
                            <div>
                                <span
                                    data-tooltip-id='enterprise-tooltip'
                                    className='text-contrast-3 cursor-pointer'
                                >
                                    Enterprise
                                </span>
                                <Tooltip
                                    id='enterprise-tooltip'
                                    place='top'
                                    effect='solid'
                                    delayShow={200}
                                    delayHide={200}
                                    className='!bg-white !border !border-black dark:!bg-black !text-xs !text-white dark:!text-contrast-6 !max-w-[320px] !p-2 !rounded-lg z-10 !shadow-lg'
                                    clickable={true}
                                >
                                    <div className='flex flex-col gap-4 text-sm'>
                                        {ENTERPRISE_LINKS.map(
                                            ({ title, link, blank }) => (
                                                <Link
                                                    key={link}
                                                    href={link}
                                                    blank={blank}
                                                >
                                                    <OptionButton
                                                        className='!justify-start'
                                                        text={
                                                            <div className='flex items-center gap-4'>
                                                                <div className='flex items-center gap-1'>
                                                                    <span className='text-sm font-bold'>
                                                                        {title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </Link>
                                            )
                                        )}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex flex-col justify-end items-end gap-4 md:flex-row md:items-center'>
                            {LEGAL_LINKS.map(({ title, link, blank }) => (
                                <FooterLink
                                    key={link}
                                    title={title}
                                    link={link}
                                    blank={blank}
                                />
                            ))}
                            <span className='text-contrast-3'>{`${new Date().getFullYear()}©Staking Rewards.`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
