import {
    ASSETS_UNDER_MANAGEMENT_METRIC_KEY,
    CUSTOM_AUM_CHANGE_METRIC_KEY,
} from 'utils/constants'

const COLUMN_WIDTH = '120px'

export const MOMENTUM_COLUMN = 'Momentum'
export const GENERAL_COLUMN = 'General'
export const RISK_COLUMN = 'Risk'

export const TOOLTIPS = {
    aum: {
        title: 'Assets under Management (AuM)',
        text: "This metric reflects the cumulative worth of all the assets entrusted to the provider's management, encompassing various forms of staking and delegation activities. AuM offers insights into the provider's size, performance, and overall reach in the staking and crypto ecosystems. A higher AuM suggests substantial user trust, while a lower AuM might indicate a more specialized or selective approach. This metric is essential for evaluating the provider's prominence and potential impact in the staking and cryptocurrency realms.",
    },
    aumChange: (timeframeKey = '') => ({
        title: `AuM Change ${timeframeKey}`,
        text: `The absolute variation in the assets under management (AuM) of a provider's managed assets over the timeframe of ${timeframeKey} is quantified by this metric. A positive value indicates an increase in the total value of assets managed, while a negative value signifies a decline. This metric provides a snapshot of short-term trends but may not capture larger market dynamics that could influence AuM.`,
    }),
    stakingWallets: {
        title: 'Staking Wallets',
        text: "This metric shows the number of addresses that have chosen the provider for staking or delegation. It indicates the provider's presence and reputation within various ecosystems. A higher count suggests a larger user base, while a lower count may indicate specialization. It is crucial for evaluating the provider's influence and potential across diverse networks.",
    },
}

export const MAIN_PROVIDER_METRIC_GROUPS = [
    {
        key: ASSETS_UNDER_MANAGEMENT_METRIC_KEY,
        label: 'Total AuM',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.aum,
        withPercentage: true,
        width: '130px',
        showPlus: false,
        column: RISK_COLUMN,
        withColor: true,
    },
    {
        key: CUSTOM_AUM_CHANGE_METRIC_KEY,
        label: 'AuM Change',
        prefix: '$',
        tooltipTextObj: timeframeKey => TOOLTIPS.aumChange(timeframeKey),
        withPercentage: false,
        width: '100px',
        showPlus: true,
        withColor: false,
        withTimeframe: true,
    },
    {
        key: 'staking_wallets',
        label: 'Stakers',
        postfix: '',
        tooltipTextObj: TOOLTIPS.stakingWallets,
        withPercentage: true,
        width: '110px',
        showPlus: false,
        column: GENERAL_COLUMN,
        withColor: true,
    },
    {
        key: 'vsp',
        label: 'SR Rating',
        prefix: '',
        tooltipTextObj: null,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        withColor: false,
    },
    {
        key: 'hosting_fee',
        label: 'Fee Type',
        prefix: '$',
        tooltipTextObj: null,
        withPercentage: true,
        width: '90px',
        showPlus: false,
        withColor: false,
    },
]

export const MORE_PROVIDER_METRIC_GROUPS = [
    {
        key: 'page_views_trend',
        label: 'Page Views Trend',
        postfix: '%',
        tooltipTextObj: null,
        withPercentage: true,
        width: '130px',
        showPlus: false,
        column: MOMENTUM_COLUMN,
        withColor: true,
    },
    {
        key: 'net_staking_flow_7d',
        label: 'Net Staking Flow 7d',
        prefix: '$',
        tooltipTextObj: null,
        withPercentage: false,
        width: '130px',
        showPlus: true,
        withColor: true,
    },
]

export const PROVIDERS_SORT_BY_CHOICES = MAIN_PROVIDER_METRIC_GROUPS.map(
    group => ({
        key: group.key,
        name: group.label,
        withPercentage: group.withPercentage,
    })
)

export const DEFAULT_PROVIDERS_SORT_BY = PROVIDERS_SORT_BY_CHOICES.find(
    choice => choice.key === ASSETS_UNDER_MANAGEMENT_METRIC_KEY
)

export const VSP_PRO_PROVIDERS = ['kiln', 'luganodes']
