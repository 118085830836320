import CookieConsent from 'react-cookie-consent'
import { Link } from './links/link'

export const CookieBanner = () => {
    return (
        <CookieConsent
            location='bottom'
            buttonText='Got It!'
            cookieName='cookieConsent'
            style={{
                background: 'var(--c-fg)',
                color: 'var(--c-bg)',
                padding: '4px 12px',
                alignItems: 'center',
            }}
            buttonStyle={{
                background: 'var(--c-blue)',
                color: 'var(--c-lightest)',
                fontSize: '14px',
                padding: '8px 40px',
                marginLeft: '20px',
            }}
            expires={5 * 365} // in 5 years
        >
            This site uses cookies to provide you with a great user experience.
            By using Staking Rewards, you accept our{' '}
            <Link
                className='font-bold hover:underline'
                href={'/privacy-policy'}
            >
                use of cookies
            </Link>
            .
        </CookieConsent>
    )
}
