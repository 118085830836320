import classNames from 'classnames'

import styles from './resetButton.module.scss'

export const ResetButton = ({
    className = '',
    show = false,
    onClick = () => {},
}) => {
    return (
        <button
            className={classNames(styles.resetButton, className, {
                [styles.hidden]: !show,
            })}
            onClick={onClick}
        >
            <span className='icon icon-og icon-reset' />
        </button>
    )
}
