import { useState, useEffect, useRef, useCallback } from 'react'

const useUnmount = fn => {
    const fnRef = useRef(fn)
    // update the ref each render so that if it changes the newest callback is invoked
    fnRef.current = fn
    useEffect(() => () => fnRef.current(), [])
}

// Update the state only when the browser is ready to render the next frame.
// to reduce the number of rerenders
const useRafState = initialState => {
    const frame = useRef(0)
    const [state, setState] = useState(initialState)

    const setRafState = useCallback(value => {
        cancelAnimationFrame(frame.current)

        frame.current = requestAnimationFrame(() => {
            setState(value)
        })
    }, [])

    useUnmount(() => {
        cancelAnimationFrame(frame.current)
    })

    return [state, setRafState]
}

export const useWindowSize = (initialWidth = 0, initialHeight = 0) => {
    const isBrowser = typeof window !== 'undefined'

    const [state, setState] = useRafState({
        width: isBrowser ? window.innerWidth : initialWidth,
        height: isBrowser ? window.innerHeight : initialHeight,
    })

    useEffect(() => {
        if (isBrowser) {
            const handler = () => {
                setState({
                    width: window.innerWidth,
                    height: window.innerHeight,
                })
            }

            window.addEventListener('resize', handler)
            return () => {
                window.removeEventListener('resize', handler)
            }
        }
    }, [isBrowser, setState])

    return state
}

/**
 * Implements useMediaPredicate('(min-width: minWidthPx)') from 'react-media-hook' library, returning the true client-side 
 * width predicate before mounting the component. Needed for the components adapting their size based on the client window width
 * without rerendering causing layout shifts. 
 * NB: to avoid hydration errors compared to the SSR rendering results, the components using the hook should either be rendered 
 * dynamically with ssr: false, or rendered after the parent component is mounted.
 */
export const useClientWidthMediaPredicate = (minWidthPx = 768) => {
    const { width } = useWindowSize()
    return width >= minWidthPx
}
