/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { uiState } from 'state'
import { ScrollTrigger } from 'utils/gsap'
import { useRouter } from 'next/router'
import { useNavigationContext } from 'contexts/navigationContext'

export function Route({ children }) {
    const router = useRouter()
    const { setOverlayScreen } = useNavigationContext()

    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    useEffect(() => {
        uiState.scrolled.value = false
        // document.body.scrollTop = 0

        ScrollTrigger.create({
            start: '80px',
            end: '+=99999',
            onToggle: self => {
                uiState.scrolled.value = self.isActive
            },
            onUpdate: self => {
                uiState.scrollDir.value = self.direction
            },
            // markers: true,
        })

        ScrollTrigger.refresh()

        return () => {
            setOverlayScreen(null)
        }
    }, [router])

    return (
        <main
            style={{
                opacity: isLoaded ? 1 : 0,
                transition: 'opacity 0.8s linear',
            }}
        >
            {children}
        </main>
    )
}
