import { useMemo, useState } from 'react'
import Image from 'next/image'
import { useMostViewedAssets, useTrendingAssets } from 'data/queries'
import {
    ASSET_CATEGORIES,
    DEFAULT_ASSET_CATEGORY,
    TYPE_ASSET,
} from 'utils/constants'
import { ASSETS_MENU_ITEMS } from './constants'
import { StackedItems } from './stackedItems'
import { WithSearch } from './search'
import { NavigationMenu } from './navigationMenu'
import { FooterLink } from './viewWithLinks'

import styles from './assetsView.module.scss'

const AssetItemsView = ({ selectedCategoryKey = null }) => {
    const { data, isValidating: isLoading } = useMostViewedAssets(
        selectedCategoryKey ?? DEFAULT_ASSET_CATEGORY.key,
        10,
        0
    )

    return (
        <div className={styles.itemsWrap}>
            <p className={styles.title}>Most Viewed</p>
            <StackedItems
                data={data?.assets}
                isLoading={isLoading}
                type={TYPE_ASSET}
            />
        </div>
    )
}

export const AssetsView = ({ isDesktop = true }) => {
    const [selectedCategoryKey, setSelectedCategoryKey] = useState(
        DEFAULT_ASSET_CATEGORY.key
    )

    // Memoize for memoizing NavigationMenu
    const onSelect = useMemo(
        () => newCategory => {
            setSelectedCategoryKey(newCategory?.key)
        },
        []
    )

    return (
        <div className={styles.assetsView}>
            <div className={styles.navigationWrap}>
                {isDesktop ? (
                    <NavigationMenu
                        items={ASSETS_MENU_ITEMS}
                        selected={selectedCategoryKey}
                        onSelect={onSelect}
                        selectOnHover={isDesktop}
                    />
                ) : (
                    <div className={styles.links}>
                        {ASSET_CATEGORIES.map((category, idx) => (
                            <FooterLink
                                key={`category-link-${category?.name}-${idx}`}
                                link={{
                                    title: category?.name,
                                    href: `/assets/${category?.key}`,
                                    blank: false,
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>
            {isDesktop && (
                <AssetItemsView selectedCategoryKey={selectedCategoryKey} />
            )}
        </div>
    )
}

export const TrendingAssetsView = ({ isFooter = false }) => {
    const { data, isValidating: isLoading } = useTrendingAssets(null, 10, 0)

    return (
        <WithSearch focusOnMount={!isFooter}>
            <div className={styles.itemsWrap}>
                <p className={styles.title}>
                    Trending{' '}
                    <span className={styles.icon}>
                        <Image
                            src='/static/svg/flame.svg'
                            alt='Trending'
                            fill
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        />
                    </span>
                </p>
                <StackedItems
                    data={data?.assets}
                    isLoading={isLoading}
                    type={TYPE_ASSET}
                />
            </div>
        </WithSearch>
    )
}
