import dynamic from 'next/dynamic'
import { useMediaPredicate } from 'react-media-hook'
import { useRouterPages } from 'components/navbar/hooks'
import { useContainerScrollToTop } from './useContainerScrollToTop'
import { Footer } from 'components/footer'
import { FloatingBanner } from 'components/ads/floatingBanner'
import { FeedbackButton } from 'components/ui/buttons/feedbackButton'

const PageControl = dynamic(
    () => import('components/pageControl/').then(module => module.PageControl),
    {
        ssr: false,
    }
)

export function ContentContainer({ children }) {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const containerRef = useContainerScrollToTop()

    const { isStakeAppPage, isSwapAppPage } = useRouterPages()

    return (
        <div
            className='w-full h-full overflow-x-hidden overflow-y-auto'
            ref={containerRef}
        >
            {children}
            {isTablet && <FeedbackButton />}
            {!isStakeAppPage && !isSwapAppPage && <FloatingBanner />}
            <PageControl />
            <Footer />
        </div>
    )
}
