import classNames from 'classnames'
import { useTheme, storageState, THEME } from 'state'
import { useNavigationContext } from 'contexts/navigationContext'

const NavigationButton = () => {
    const { overlayScreen, setOverlayScreen } = useNavigationContext()

    return (
        <span
            className={
                'icon icon-navbar [--size:24px] [--color:--c-contrast-3]'
            }
            role='button'
            aria-label='Toggle Menu'
            onClick={() =>
                setOverlayScreen(overlayScreen ? null : 'mobileNavbar')
            }
        />
    )
}

const SearchButton = () => {
    const { overlayScreen, setOverlayScreen } = useNavigationContext()

    return (
        <span
            className={
                'icon icon-search [--size:24px] [--color:--c-contrast-3]'
            }
            role='button'
            aria-label='Toggle Menu'
            onClick={() => setOverlayScreen(overlayScreen ? null : 'search')}
        />
    )
}

const ThemeButton = () => {
    const theme = useTheme()
    const isThemeDark = theme === THEME.DARK

    return (
        <span
            className={classNames(
                'icon [--size:26px] [--color:--c-contrast-3]',
                isThemeDark ? 'icon-sun' : 'icon-moon'
            )}
            role='button'
            aria-label='Switch Theme'
            onClick={() => {
                storageState.theme.value = isThemeDark
                    ? THEME.LIGHT
                    : THEME.DARK
            }}
        />
    )
}

export function MobileButtons() {
    return (
        <div className='flex items-center gap-8 md:hidden'>
            <ThemeButton />
            <SearchButton />
            <NavigationButton />
        </div>
    )
}
