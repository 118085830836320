import { useState, useRef, useEffect } from 'react'
import { clipboardCopy } from 'utils/actions'

/**
 * Hook to copy text to the clipboard with visual feedback.
 *
 * This hook provides a method to copy a specified text to the clipboard
 * and returns the current status message for visual feedback to the user.
 * After the copying action, the status message will revert to its initial
 * state after a timeout of 3 seconds.
 *
 * @param {string} textToCopy - The text that needs to be copied to the clipboard.
 * @param {string} initialMessage - The initial message state before and after copying.
 *
 * @returns {Object} - Contains the current status message (copyingMessage) and
 *                     a function to initiate the copy (handleClickCopy).
 *
 * @example
 * const { copyingMessage, handleClickCopy } = useClickWithTimeout('Text to copy', 'Click to copy');
 * <button onClick={handleClickCopy}>{copyingMessage}</button>
 *
 * Note: Uses the browser navigator.clipboard.writeText() for copying. Ensure it is supported
 * in the target browsers.
 */
export const useClickWithTimeout = (textToCopy = '', initialMessage = '') => {
    const [copyingMessage, setCopyingMessage] = useState(initialMessage)
    const [isCopied, setIsCopied] = useState(false)
    const timeoutRef = useRef(null)
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    const handleClickCopy = async e => {
        e.preventDefault()
        setIsCopied(true)
        setCopyingMessage('Copying')
        const response = await clipboardCopy(textToCopy)
        setCopyingMessage(response)
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            setIsCopied(false)
            setCopyingMessage(initialMessage)
        }, 3000)
    }

    return { copyingMessage, handleClickCopy, isCopied }
}
