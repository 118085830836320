import classNames from 'classnames'
import { Link } from './link'

import styles from './externalLink.module.scss'

export const ExternalLinkVariant = {
    Primary: 'primary',
    Fg: 'fg',
}

export const ExternalLink = ({
    href = '',
    variant = ExternalLinkVariant.Fg,
    label = '',
    large = false,
    className = '',
    icon = '',
}) => {
    return (
        <Link
            className={classNames(
                styles.externalLink,
                {
                    [styles.primary]: variant === ExternalLinkVariant.Primary,
                    [styles.large]: large,
                },
                className
            )}
            href={href}
            blank
        >
            <span className={classNames(`icon ${icon}`, styles.smallIcon)} />
            <span className={styles.label}>{label}</span>
            <span className={classNames(`icon icon-external`, styles.icon)} />
        </Link>
    )
}
