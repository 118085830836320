export const PRODUCT_LINKS = [
    {
        title: 'Jobs',
        link: 'https://staking-rewards.breezy.hr/',
        blank: true,
    },
    {
        title: 'VSP',
        link: '/vsp',
        blank: false,
    },
]

export const ENTERPRISE_LINKS = [
    {
        title: 'Institutional Investors',
        link: 'https://stakingrewards.typeform.com/institutional',
        blank: true,
    },
    {
        title: 'Sponsor the Summit',
        link: 'https://airtable.com/app6cnGzd6gGK8AMu/shrf5YINcCUvz3Xpb',
        blank: true,
    },
    {
        title: 'Get Your Asset Integrated',
        link: 'https://form.jotform.com/240331913572350',
        blank: true,
    },
    {
        title: 'Advertise',
        link: 'https://stakingrewards.typeform.com/to/uEzXLMSV',
        blank: true,
    },
    {
        title: 'Staking Data API',
        link: 'https://stakingrewards.typeform.com/to/hXmkLb11',
        blank: true,
    },
    {
        title: 'Get Verified',
        link: 'https://stakingrewards.typeform.com/get-verified',
        blank: true,
    },
    {
        title: 'Claim Provider Profile',
        link: 'https://stakingrewards.typeform.com/claim-profile',
        blank: true,
    },
]

export const LEGAL_LINKS = [
    {
        title: 'Disclaimer',
        link: '/disclaimer',
        blank: false,
    },
    {
        title: 'Privacy Policy',
        link: '/privacy-policy',
        blank: false,
    },
    {
        title: 'Terms of Service',
        link: '/terms-and-conditions',
        blank: false,
    },
]
