export const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' // Wrapped ETH
export const stETH_ADDRESS = '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84' // Lido stETH
export const wstETH_ADDRESS = '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0' // Lido wrapped stETH
export const rETH_ADDRESS = '0xae78736Cd615f374D3085123A210448E74Fc6393' // Rocketpool rETH
export const sETH2_ADDRESS = '0xFe2e637202056d30016725477c5da089Ab0A043A' // Stakewise sETH2 staking token
export const rETH2_ADDRESS = '0x20BC832ca081b91433ff6c17f85701B6e92486c5' // Stakewise rETH2 reward token
export const ETHx_ADDRESS = '0xA35b1B31Ce002FBF2058D22F30f95D405200A15b' // Stader ETHx

export const ankrETH_ADDRESS = '0xE95A203B1a91a908F9B9CE46459d101078c2c3cb' // Ankr Staked ETH
export const eETH_ADDRESS = '0x35fA164735182de50811E8e2E824cFb9B6118ac2' // Ether.fi ETH
export const weETH_ADDRESS = '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee' // Wrapped Ether.fi ETH
export const osETH_ADDRESS = '0xf1C9acDc66974dFB6dEcB12aA385b9cD01190E38' // Stakewise osETH
export const mevETH_ADDRESS = '0x24Ae2dA0f361AA4BE46b48EB19C91e02c5e4f27E' // MEV ETH

export const LoginType = {
    WALLET: 'wallet', // any wallet from Dynamic
    ADDRESS: 'address',
}

export const TRANSFER_STATUS = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    CHAIN_SWITCH_REQUIRED: 'CHAIN_SWITCH_REQUIRED',
    ACTION_REQUIRED: 'ACTION_REQUIRED',
    CONFIRMING: 'CONFIRMING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
}

export const STAKING_START_DATE = new Date('2020-12-01')

// TODO: move it to CoinManager/randomize
export const getColorForPortfolioChart = (slug = '') => {
    if (slug === 'ethereum-2-0') return '#8c8c8c'
    if (slug === 'weth') return '#444444'

    if (slug === 'wrapped-steth') return '#023bff'
    if (slug === 'rocket-pool-eth') return '#fea73f'
    if (slug === 'stader-ethx') return '#00A455'
    if (slug === 'ankreth') return '#2e2e2e'
    if (slug === 'wrapped-eeth') return '#00A455'
    if (slug === 'stakewise-v3-oseth') return '#00A455'
    if (slug === 'meveth') return '#00A455'

    return 'var(--c-bw)'
}
