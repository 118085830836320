import { useEffect } from 'react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'

export const useProgressBar = () => {
    const router = useRouter()

    useEffect(() => {
        NProgress.configure({
            minimum: 0.75,
            speed: 450,
            easing: 'ease',
            showSpinner: false,
        })

        function handleRouteChangeStart() {
            NProgress.start()
        }

        function handleRouteChangeComplete() {
            NProgress.done()
        }

        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        router.events.on('routeChangeError', handleRouteChangeComplete)

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
            router.events.off('routeChangeError', handleRouteChangeComplete)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
