import React from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useTheme } from 'state/storage'

import styles from './scrollbarWithGradient.module.scss'

export const DIRECTION_HORIZONTAL = 'horizontal'
export const DIRECTION_VERTICAL = 'vertical'

// TODO: move the logic for the vertical scroll here as well
export const ScrollbarWithGradient = ({
    className = '',
    direction = DIRECTION_VERTICAL,
    maxMediaBreakPoint = '1440px',
    disableScroll = false,
    bgColor = true,
    children,
}) => {
    const scrollable = useMediaPredicate(`(max-width: ${maxMediaBreakPoint})`)
    const theme = useTheme()
    return (
        <div
            className={classNames(
                styles.scrollbarWithGradient,
                {
                    [`overflow-gradient-right ${styles.horizontal}`]:
                        direction === DIRECTION_HORIZONTAL,
                    ['horizontal-scroll']:
                        direction === DIRECTION_HORIZONTAL && !disableScroll,
                    [styles.bg]: bgColor,
                    [styles.toggleGradient]: !scrollable,
                },
                className
            )}
            data-theme={theme}
        >
            {React.cloneElement(children, {
                className: classNames(
                    children?.props?.className || '',
                    styles.childrenWrap,
                    { [styles.scrollable]: scrollable }
                ),
            })}
            <div className={styles.scrollPlaceholder} />
        </div>
    )
}
