import { useMediaPredicate } from 'react-media-hook'

export function useScreenSize() {
    const tablet = useMediaPredicate('(min-width: 768px)')
    const desktop = useMediaPredicate('(min-width: 1024px)')
    const desktopLg = useMediaPredicate('(min-width: 1200px)')
    const desktopXl = useMediaPredicate('(min-width: 1600px)')

    return {
        tablet,
        desktop,
        desktopLg,
        desktopXl,
    }
}
