import ReactConfetti from 'react-dom-confetti'

const config = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 50,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

export const Confetti = ({ isActive = false }) => {
    return <ReactConfetti active={isActive} config={config} />
}
