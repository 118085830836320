import { useState, useEffect } from 'react'
import { getGoogleTag } from './utils'
import { useTheme } from 'state'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { fetchAssetTags } from 'data/queries'
import { findByEcosystem } from 'utils/findByEcosystem'
import { findByTag } from 'utils/findByTag'
import {
    BITCOIN_AND_OTHERS_KEY,
    BNB_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    ETHEREUM_ECOSYSTEM_KEY,
    LIQUID_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    NATIVE_RESTAKING_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    POS_ASSET_CATEGORY,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
} from 'utils/constants'

const checkForAdblocker = () => {
    return new Promise(resolve => {
        if (navigator?.doNotTrack === '1') {
            resolve(true) // AdBlocker detected due to "Do Not Track"
            return
        }

        const script = document.createElement('script')
        script.src =
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

        const onScriptLoad = () => {
            document.body.removeChild(script) // Clean up the script
            resolve(false) // AdBlocker not detected
        }

        const onScriptError = () => {
            document.body.removeChild(script) // Clean up the script
            resolve(true) // AdBlocker detected
        }

        script.onload = onScriptLoad
        script.onerror = onScriptError

        document.body.appendChild(script)
    })
}

/**
 * Initialize this on _app.js only once, then access it from getGoogleTag()
 */
export const useGoogleTag = () => {
    const [googleTag, setGoogleTag] = useState(null)

    useEffect(() => {
        checkForAdblocker().then(hasAdBlocker => {
            if (!hasAdBlocker && !googleTag) {
                const script = document.createElement('script')
                script.src =
                    'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
                script.onload = () => {
                    const newTag = getGoogleTag()
                    setGoogleTag(newTag)
                    newTag.cmd.push(function () {
                        newTag.enableServices()
                    })
                }
                script.onerror = () => {
                    setGoogleTag(null)
                }
                document.body.appendChild(script)
                return () => {
                    document.body.removeChild(script)
                }
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return { googleTag }
}

export const useAdSlot = ({ adId, slotId, size, type, slug, targeting }) => {
    const theme = useTheme()

    const [existingAd, setExistingAd] = useState(null)
    const [isRendered, setIsRendered] = useState(false)

    /**
     * Send the theme to the ad iframe
     */
    useEffect(() => {
        const iframe = document.querySelector(`#${adId} iframe`)
        if (!iframe) return

        const switchGoogleAdTheme = theme => {
            if (iframe?.contentWindow) {
                iframe.contentWindow.postMessage({ theme }, '*')
            }
        }
        switchGoogleAdTheme(theme)
    })

    const googletag = getGoogleTag()

    // Destroy the slot when the component unmounts, otherwise other assets will not be able to render ads
    useEffect(() => {
        return () => {
            if (googletag) {
                googletag.cmd.push(() => {
                    googletag.destroySlots([
                        googletag
                            .pubads()
                            .getSlots()
                            .find(slot => slot.getSlotElementId() === adId),
                    ])
                })
            }
        }
    }, [googletag]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!googletag) {
            return
        }
        const command = () => {
            googletag.cmd.push(() => {
                const existingSlot = googletag
                    .pubads()
                    .getSlots()
                    .find(slot => slot.getSlotElementId() === adId)

                if (!existingSlot && !existingAd) {
                    const newSlot = googletag.defineSlot(slotId, size, adId)
                    newSlot
                        .addService(googletag.pubads())
                        // need to provide a string value for test
                        .updateTargetingFromMap({
                            ...targeting,
                            test: targeting.test ? 'true' : 'false',
                        })
                    googletag.enableServices()
                    googletag.display(adId)
                    // onRender callback
                    googletag?.pubads().addEventListener('slotOnload', e => {
                        if (e.slot === newSlot) {
                            setIsRendered(true)
                        }
                    })
                    setExistingAd(newSlot)
                    googletag.pubads().refresh([newSlot])
                } else {
                    googletag.pubads().refresh([existingSlot])
                }
            })
        }
        /**
         * If the GAM API is ready, run the command immediately, otherwise wait for it recursively every 100ms to be ready
         * This is necessary because the GAM API is not rendering slots properly otherwise
         */
        if (googletag.apiReady) {
            command()
        } else {
            waitForGAMAPIReady()
                .then(command)
                .catch(e => console.error(e))
        }
    }, [adId, slotId, size, type, slug, googletag, existingAd, targeting])

    return { isRendered }
}

const MAX_RETRIES = 50 // 5 seconds

// Recursively wait for the GAM API to be ready
function waitForGAMAPIReady(maxRetries = MAX_RETRIES) {
    let attempts = 0
    return new Promise((resolve, reject) => {
        function checkAPIReady() {
            const googletag = getGoogleTag()
            if (googletag?.apiReady) {
                resolve()
            } else if (attempts < maxRetries) {
                attempts++
                setTimeout(checkAPIReady, 100)
            } else {
                reject(new Error('GAM API not ready after max attempts'))
            }
        }
        checkAPIReady()
    })
}

export const useSharedImageAdState = () => {
    const router = useRouter()
    const { slug, page } = router.query

    const [imageAdState, setImageAdState] = useState({
        asset: null,
        tag: null,
        ecosystem: null,
    })

    const { data: assetTagsResponse } = useQuery({
        queryKey: ['assetTags', slug],
        queryFn: () => fetchAssetTags(slug),
        enabled: Boolean(
            slug &&
            router.asPath.startsWith('/asset/') &&
            !router.asPath.startsWith('/assets')
        ),
    })

    useEffect(() => {
        if (
            router.asPath.startsWith('/asset/') &&
            !router.asPath.startsWith('/assets')
        ) {
            if (assetTagsResponse) {
                const tagKeys =
                    assetTagsResponse?.assets?.[0]?.tags?.map(
                        tag => tag.tagKey
                    ) || []
                const tagKeyEcosystem = findByEcosystem(tagKeys)
                const tagKeyTag = findByTag(tagKeys)

                setImageAdState({
                    asset: slug,
                    tag: tagKeyTag || null,
                    ecosystem: tagKeyEcosystem || null,
                })
            }
        } else if (router.asPath.startsWith('/assets')) {
            const isTag =
                page === LIQUID_STAKING_KEY ||
                page === STABLECOIN_KEY ||
                page === BITCOIN_AND_OTHERS_KEY ||
                page === LIQUID_RESTAKING_KEY ||
                page === NATIVE_RESTAKING_KEY ||
                page === POS_ASSET_CATEGORY.key ||
                page === STABLECOINS_ASSET_CATEGORY.key

            const isEcosystem =
                page === ETHEREUM_ECOSYSTEM_KEY ||
                page === COSMOS_ECOSYSTEM_KEY ||
                page === POLKADOT_ECOSYSTEM_KEY ||
                page === BNB_ECOSYSTEM_KEY

                setImageAdState({
                asset: null,
                tag: isTag ? page : null,
                ecosystem: isEcosystem ? page : null,
            })
        } else {
            setImageAdState({
                asset: null,
                tag: null,
                ecosystem: null,
            })
        }
    }, [router.asPath, page, slug, assetTagsResponse])

    return { imageAdState }
}
