import { useEffect } from 'react'

export const useLockBodyScroll = (isOpen = false) => {
    useEffect(() => {
        if (isOpen) {
            document.documentElement.style.overflowY = 'hidden'
            document.body.style.overflowY = 'hidden'
        } else {
            document.documentElement.style.overflowY = 'auto'
            document.body.style.overflowY = 'auto'
        }

        return () => {
            document.documentElement.style.overflowY = 'auto'
            document.body.style.overflowY = 'auto'
        }
    }, [isOpen])
}
