import { assetSections } from 'components/header/constants/assetSections'
import { providerSections } from 'components/header/constants/providerSections'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

const mapPageTypeToSections = {
    asset: assetSections,
    provider: providerSections,
}

export function usePageSections() {
    const router = useRouter()

    const query = router.query
    const path = router.asPath

    const { slug, sectionSlug: sectionParam = [''] } = query
    const [sectionSlug] = sectionParam

    const pageType = path.split('/')[1]
    const pageSections = useMemo(
        () => mapPageTypeToSections[pageType] ?? [],
        [pageType]
    )

    const [sectionId, setSectionId] = useState(null)

    // get section from route
    useEffect(() => {
        if (Array.isArray(pageSections)) {
            const section = pageSections.find(
                ({ slug }) => slug === sectionSlug
            )

            if (section) {
                setSectionId(section.id)
            }
        }
    }, [pageSections, sectionSlug])

    // push next section to route
    const setSelectedSectionId = useCallback(
        nextSectionId => {
            if (Array.isArray(pageSections)) {
                const nextSection = pageSections.find(
                    ({ id }) => id === nextSectionId
                )

                if (nextSection) {
                    const nextPath = `/${pageType}/${slug}/${nextSection.slug}`

                    router.push(nextPath, undefined, {
                        shallow: true,
                    })
                }
            }
        },
        [pageType, router, pageSections, slug]
    )

    return {
        selectedSectionId: sectionId,
        setSelectedSectionId,
        pageSections,
    }
}
